import * as yup from "yup";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { CREATE_EXAM_INVITE_ADDITION_PATIENT_FIELDS as ADDITION_FIELDS } from "../../../api/qualiphy/useQualiphyCreateExamInviteMutation";
import { useExtendedFormik } from "../../../hooks/useExtendedFormik";
import { QUALIPHY_EXAM_TYPES } from "../../../api/qualiphy/useQualiphyExamsQuery";
import { uiNotification } from "../../../services/UINotificationService";
import { isNullish } from "../../../utilities/general";
import { phoneUtil } from "../../../utilities/phone";
import { useClientQuery } from "../../../api/queries/useClientQuery";
import { Maybe } from "../../../utilities/fp";

const isSimple = (x) => x.type === QUALIPHY_EXAM_TYPES.simple;
const isRx = (x) => x.type === QUALIPHY_EXAM_TYPES.rx;

const additionFieldsFormMap = {
  [ADDITION_FIELDS.dateOfBirth]: "dateOfBirth",
  [ADDITION_FIELDS.email]: "email",
  [ADDITION_FIELDS.phone]: "phone",
  [ADDITION_FIELDS.firstName]: "firstName",
  [ADDITION_FIELDS.lastName]: "lastName",
  [ADDITION_FIELDS.gender]: "gender",
  [ADDITION_FIELDS.address]: "address",
  [ADDITION_FIELDS.city]: "city",
  [ADDITION_FIELDS.state]: "state",
  [ADDITION_FIELDS.pincode]: "pincode",
  [ADDITION_FIELDS.shippingAddress]: "shippingAddress",
  [ADDITION_FIELDS.shippingCity]: "shippingCity",
  [ADDITION_FIELDS.shippingState]: "shippingState",
  [ADDITION_FIELDS.shippingZipcode]: "shippingZipcode",
};

const additionFieldsSchemas = {
  email: yup.string().email().required(),
  phone: yup.string().test({
    test: (value) => !value || phoneUtil.isPossible(phoneUtil.compose(value)),
  }),
};

export function useForm({
  onSubmit,
  additionFields,
  initialValues,
  setShippingAddressFieldsVisibility,
  setAddressFieldsVisibility,
  patientId,
}) {
  const { tCommon } = useAppTranslation.Common();

  const { data: patient } = useClientQuery(
    { clientId: Maybe.of(patientId).map(Number).orElse(0).value() },
    {
      enabled: !!patientId,
    },
  );

  const { values, getError, setFieldValue, handleSubmit, isValid } =
    useExtendedFormik({
      validationSchema: yup.object({
        clinicId: yup
          .number()
          .required(tCommon("createQualifyProcedure.formError.clinicRequired")),

        exams: yup
          .array()
          .of(
            yup.object({
              value: yup.number(),
              label: yup.string(),
              isAttachmentsRequired: yup.bool(),
              rx: yup.number(),
              type: yup.string(),
            }),
          )
          .min(1, tCommon("createQualifyProcedure.formError.examsMin1"))
          .required(tCommon("createQualifyProcedure.formError.examsRequired")),

        attachments: yup.array().of(yup.object()),

        pharmacyPackage: yup.lazy((_, { context }) => {
          const schema = yup
            .object({
              label: yup.string(),
              value: yup.number(),
              pharmacyId: yup.number(),
            })
            .nullable();

          if (context.isPharmacyPackagesAvailable && context.exams.some(isRx)) {
            return schema.required(
              tCommon(
                "createQualifyProcedure.formError.pharmacyPackageRequired",
              ),
            );
          }

          return schema;
        }),

        state: yup.string().required(tCommon("formError.required")),

        sameShipping: yup.boolean().required(),

        isPharmacyPackagesAvailable: yup.boolean().required(),

        dateOfBirth: yup.string().nullable(),

        email: yup.string().nullable(),

        firstName: yup.string().nullable(),

        lastName: yup.string().nullable(),

        phone: yup.string().nullable(),

        gender: yup.number().nullable(),

        address: yup.string().nullable(),

        city: yup.string().nullable(),

        pincode: yup.string().nullable(),

        shippingAddress: yup.string().nullable(),

        shippingCity: yup.string().nullable(),

        shippingState: yup.string().nullable(),

        shippingZipcode: yup.string().nullable(),
      }),
      enableReinitialize: true,
      onSubmit,
      initialValues: {
        clinicId: "",
        exams: [],
        attachments: [],
        pharmacyPackage: null,
        state: patient?.state || "",
        sameShipping: false,
        isPharmacyPackagesAvailable: false,
        dateOfBirth: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        gender: null,
        address: patient?.address1 || "",
        city: patient?.city || "",
        pincode: patient?.pinCode || "",
        shippingAddress: "",
        shippingCity: "",
        shippingState: "",
        shippingZipcode: "",
        ...initialValues,
      },
    });

  const validateAdditionFields = (requiredFields, formValues) =>
    new Promise((res, rej) => {
      const isSomeNotFilled = requiredFields
        .map((x) => formValues[additionFieldsFormMap[x]])
        .some((x) => isNullish(x) || x === "");

      if (isSomeNotFilled) {
        rej(tCommon("createQualifyProcedure.error.additionFieldsRequired"));
      }

      if (
        requiredFields.includes(ADDITION_FIELDS.email) &&
        !additionFieldsSchemas.email.isValidSync(values.email)
      ) {
        rej(tCommon("formError.emailInvalid"));
      }

      if (
        requiredFields.includes(ADDITION_FIELDS.phone) &&
        !additionFieldsSchemas.phone.isValidSync(values.phone)
      ) {
        rej(tCommon("formError.phoneInvalid"));
      }

      res();
    });

  var onToggleSameShipping = (next) => {
    setFieldValue("sameShipping", next);

    var nextValue = (x) => (next ? x : "");

    setFieldValue("shippingAddress", nextValue(values.address));
    setFieldValue("shippingCity", nextValue(values.city));
    setFieldValue("shippingState", nextValue(values.state));
    setFieldValue("shippingZipcode", nextValue(values.pincode));
  };

  return {
    form: values,
    getError,
    isValid,
    setFormValue: setFieldValue,

    submit: () => {
      validateAdditionFields(additionFields, values)
        .then(handleSubmit)
        .catch(uiNotification.error.bind(uiNotification));
    },

    exams: {
      value: values.exams,
      onChange: (options) => {
        if (options.some(isRx)) {
          setAddressFieldsVisibility(true);
          onToggleSameShipping(true);
        } else {
          setAddressFieldsVisibility(false);
        }

        if (options.some(isSimple) && options.some(isRx)) {
          return uiNotification.error(
            tCommon("createQualifyProcedure.error.noRxWithOthers"),
          );
        }

        if (options.filter(isRx).length > 1) {
          return uiNotification.error(
            tCommon("createQualifyProcedure.error.onlyOneRx"),
          );
        }

        if (options.length === 0) {
          setFieldValue("pharmacyPackage", null);
          setFieldValue("isPharmacyPackagesAvailable", false);
          onToggleSameShipping(false);
        }

        setFieldValue("exams", options);
      },
      getError: () => getError("exams"),
      isRxSelected: () => values.exams.some(isRx),
    },

    sameShipping: {
      value: values.sameShipping,
      onToggle: (next) => {
        setShippingAddressFieldsVisibility(!next);
        onToggleSameShipping(next);
        setFieldValue("pharmacyPackage", null);
      },
    },
  };
}
