/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { withRouter } from "react-router";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { showFormattedDate } from "../../../Utils/services.js";
import FilterMonth from "../../../Components/Common/FilterMonth.js";
import { MEMBERSHIP_REPORT_SLUGS } from "./Sales.consts.js";

const apiDateFormat = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

const colourStyles = {
  menu: (styles) => {
    return {
      ...styles,
      top: 30,
      zIndex: 2,
    };
  },
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      border: isFocused
        ? "1px solid #cad2d6 !important"
        : "1px solid #cad2d6 !important",
      borderRadius: "3px",
      boxShadow: "none",
    };
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#047ec3" : isSelected ? "#047ec3" : "#fff",
      color: isFocused ? "#fff" : isSelected ? "#fff" : "#404040",
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: isFocused
          ? "#047ec3"
          : isSelected
          ? "#047ec3"
          : "#fff",
        color: isFocused ? "#fff" : isSelected ? "#fff" : "#404040",
      },
      borderBottom: "1px solid #dedede",
    };
  },
};

const options = [
  { value: "clinic_name", label: "By Clinic" },
  { value: "employee", label: "By Provider" },
];

class SalesActions extends Component {
  constructor(props) {
    super(props);

    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const dateData = JSON.parse(localStorage.getItem("insight-sales-date"));
    let startDate = dateData ? dateData.startDate : new Date();
    let endDate = dateData ? dateData.endDate : new Date();
    let clinicData = localStorage.getItem("insight-sales-clinicid")
      ? JSON.parse(localStorage.getItem("insight-sales-clinicid"))
      : [0];

    let month =
      props && props.parentState && props.parentState.selectedMonth
        ? props.parentState.selectedMonth
        : moment().format("MM");
    let year =
      props && props.parentState && props.parentState.selectedYear
        ? props.parentState.selectedYear
        : moment().format("YYYY");

    if (clinicData.length > 1) {
      clinicData = null;
    }

    const clinic =
      props.clinicList && clinicData
        ? props.clinicList.find((e) => e.id == clinicData[0])
        : [];

    const insightSalesTierId = JSON.parse(
      localStorage.getItem("insight-sales-tier-id"),
    );

    this.state = {
      globalLang: languageData.global,
      actionDetails: props.actionDetails,
      reportSlug: props.reportSlug,
      dateRangePicker: {
        selection: {
          startDate: startDate,
          endDate: endDate,
          key: "selection",
        },
      },
      endDate: endDate,
      toDate: format(endDate, "YYYY-MM-DD"),
      startDate: startDate,
      fromDate: format(startDate, "YYYY-MM-DD"),
      showCalendar: false,
      showClinics: false,
      clicked: 0,
      clinicName: clinic?.clinic_name || "All Clinics",
      clinicId: clinicData ? clinicData[0] : 0,
      languageData: languageData.business_insights,
      month: month,
      year: year,
      churn_filter: "both",
      filterName: "clinic_name",
      pay_period_filter: "this_week",
      pay_period_filter_label: "This Week",
      pay_period_options: [
        { slug: "this_week", label: "This Week" },
        { slug: "last_week", label: "Last Week" },
        { slug: "this_month", label: "This Month" },
        { slug: "this_month", label: "Last Month" },
      ],

      tierName:
        insightSalesTierId && insightSalesTierId.tierName
          ? insightSalesTierId.tierName
          : "All",
      tierID:
        insightSalesTierId && insightSalesTierId.tierID
          ? insightSalesTierId.tierID
          : -1,
      singleDateCalendar: false,
    };

    document.addEventListener("click", this.handleClick, false);
  }

  handleClick = (event) => {
    if (
      this.node &&
      this.node.contains(event.target) &&
      this.state.showCalendar === true
    ) {
      return;
    }

    if (
      event.target.className !== "easy-link no-padding" &&
      this.state.showClinics === true
    ) {
      this.setState({ showClinics: false });
    }

    this.toggleCalendar(event.target);
  };

  toggleCalendar = (elem) => {
    if (elem.name !== "calendar-input" && this.state.showCalendar === false) {
      return;
    }

    let showCalendar = false;

    if (
      this.state.showCalendar === false &&
      elem.name !== undefined &&
      elem.name === "calendar-input"
    ) {
      showCalendar = true;
    } else {
      showCalendar = false;
    }

    this.setState({ showCalendar: showCalendar, clicked: 0 });
  };

  dateRangeOnChange = (payload) => {
    let payloadValue = {};
    let startDate = payload.selection.startDate;
    let endDate = payload.selection.endDate;
    let clicked = this.state.clicked + 1;
    let localPref = localStorage.getItem("focusedRange");
    let canBypass = localPref && localPref === "oneClick" ? true : false;

    if (canBypass) {
      clicked = 2;
    }

    let showCalendar = true;

    if (clicked % 2 === 0) {
      showCalendar = false;
    }

    this.setState({
      showCalendar: showCalendar,
      fromDate: format(startDate, "YYYY-MM-DD"),
      toDate: format(endDate, "YYYY-MM-DD"),
      startDate: startDate,
      endDate: endDate,
      clicked: clicked,
      dateData: JSON.stringify(payloadValue),
    });

    if (clicked && clicked % 2 === 0) {
      if (payload) {
        payloadValue = {
          startDate: apiDateFormat(payload.selection.startDate),
          endDate: apiDateFormat(payload.selection.endDate),
          key: "selection",
        };
        localStorage.setItem(
          "insight-sales-date",
          JSON.stringify(payloadValue),
        );
      }

      let formData = {};

      formData.fromDate = apiDateFormat(startDate);
      formData.toDate = apiDateFormat(endDate);
      formData.canSubmit = true;
      formData.showLoader = true;

      if (
        this.props.parentState &&
        this.props.parentState.clinic_id &&
        this.props.parentState.clinic_id.length > 0
      ) {
        formData.clinic_id = this.props.parentState.clinic_id;
      }

      if (this.state.actionDetails.canShowChurnFilter) {
        formData.churn_filter = this.state.churn_filter;
      }

      if (
        this.state.actionDetails &&
        this.state.actionDetails.showComissionFilter
      ) {
        formData.report_type = this.props.parentState.report_type;
      }

      this.props.handleGrandChildFilter(formData);
    }
  };

  handleDownload = (downloadType) => {
    let formData = {};
    let ids = [];

    formData.fromDate = apiDateFormat(this.state.startDate);
    formData.toDate = apiDateFormat(this.state.endDate);
    if (this.state.clinicId == 0) {
      this.props.clinicList &&
        this.props.clinicList.map((obj) => {
          ids.push(obj.id);
        });
      formData.clinic_id = ids;
    } else if (
      this.state.clinicId > 0 &&
      this.props.clinicList &&
      this.props.clinicList.length > 0
    ) {
      formData.clinic_id = [this.state.clinicId];
    }

    if (this.state.actionDetails.canShowChurnFilter) {
      formData.churn_filter = this.state.churn_filter;
    }

    if (this.state.actionDetails.canShowMonthFilter) {
      formData = {};
      formData.month = this.state.month;
      formData.year = this.state.year;
    }

    if (this.state.actionDetails && this.state.actionDetails.showMrrFilter) {
      formData.membership_tier_id = this.state.tierID;
    }

    if (
      this.state.actionDetails &&
      this.state.actionDetails.showComissionFilter
    ) {
      formData.report_type = this.props.parentState.report_type;
    }

    formData.export_type = downloadType;
    formData.export_name = this.getExportName(this.state.reportSlug);
    this.props.handleGrandChildDownload(formData);
  };

  getExportName = (slug) => {
    switch (slug) {
      case MEMBERSHIP_REPORT_SLUGS.shopifyProducts:
        return "shopify_products";

      case MEMBERSHIP_REPORT_SLUGS.shopifySales:
        return "shopify_sales";

      default:
        return slug;
    }
  };

  changeClinic = (clinicId) => {
    let formData = {};
    let ids = [];

    if (clinicId == 0) {
      this.props.clinicList &&
        this.props.clinicList.map((obj) => {
          ids.push(obj.id);
        });

      formData.clinic_id = ids;
      this.setState({
        clinicName: this.state.languageData.bi_all_clinics,
        clinicId: 0,
      });
    } else {
      formData.clinic_id = [clinicId];
      let clinic = this.props.clinicList.find((e) => e.id == clinicId);
      this.setState({ clinicName: clinic.clinic_name, clinicId: clinicId });
    }

    localStorage.setItem(
      "insight-sales-clinicid",
      JSON.stringify(formData.clinic_id),
    );
    formData.fromDate = apiDateFormat(this.state.fromDate);
    formData.toDate = apiDateFormat(this.state.endDate);
    formData.showLoader = true;
    this.props.handleGrandChildFilter(formData);
  };

  toggleClinic = () => {
    this.setState({ showClinics: !this.state.showClinics });
  };

  handleFilterMonth = (childState) => {
    this.setState(childState);
    childState.showLoader = true;
    this.props.handleGrandChildFilter(childState);
  };

  handleChurnedReason = (value) => {
    this.setState({
      churn_filter: value,
      churnActive: true,
    });
    let formData = {};
    formData.fromDate = apiDateFormat(this.state.fromDate);
    formData.toDate = apiDateFormat(this.state.endDate);
    formData.showLoader = true;
    formData.churn_filter = value;

    this.props.handleGrandChildFilter(formData);
  };

  handleChildState = (childState) => {
    if (this.props.handleChildState) {
      this.props.handleChildState(childState);
    }
  };

  handleSelect2Change = (option) => {
    let formData = {};

    formData.fromDate = apiDateFormat(this.state.fromDate);
    formData.toDate = apiDateFormat(this.state.endDate);
    formData.canSubmit = true;
    formData.showLoader = true;
    formData.report_type = option.value;

    this.setState({ filterName: option.value });

    this.props.handleGrandChildFilter(formData);
  };

  select2Value = (selectOptions, value) => {
    let selectValue = selectOptions.find((x) => x.value == value);
    selectValue = selectValue ? selectValue : {};

    return selectValue;
  };

  handleTierChange = (tierID) => {
    let formData = {};
    let insightSalesTierId = {
      tierName: this.state.tierName,
      tierID: this.state.tierID,
    };
    if (tierID == -1) {
      this.setState({
        tierName: this.state.languageData.bi_all,
        tierID: tierID,
      });
      insightSalesTierId = {
        tierName: this.state.languageData.bi_all,
        tierID: tierID,
      };
    } else {
      let tierData =
        this.props.parentState &&
        this.props.parentState.reportData &&
        this.props.parentState.reportData.tier_list &&
        this.props.parentState.reportData.tier_list.find(
          (e) => e.tier_id == tierID,
        );
      this.setState({ tierName: tierData.tier_name, tierID: tierID });
      insightSalesTierId = {
        tierName: tierData.tier_name,
        tierID: tierID,
      };
    }

    formData.membership_tier_id = tierID;
    formData.showLoader = true;
    this.props.handleGrandChildFilter(formData);
    localStorage.setItem(
      "insight-sales-tier-id",
      JSON.stringify(insightSalesTierId),
    );
  };

  handleSingleDateChange = (isTrue) => {
    this.setState({ singleDateCalendar: isTrue });
  };

  handleSelectDate = (date) => {
    const currentDate = apiDateFormat(new Date());
    if (date != null) {
      const payloadValue = {
        startDate: currentDate,
        endDate: apiDateFormat(date),
      };
      this.setState({
        endDate: payloadValue.endDate,
        singleDateCalendar: false,
      });
      localStorage.setItem("insight-sales-date", JSON.stringify(payloadValue));
      this.props.handleGrandChildFilter({
        toDate: payloadValue.endDate,
        clinic_id: [this.state.clinicId],
      });
    } else {
      const payloadValue = {
        startDate: currentDate,
        endDate: currentDate,
      };
      this.props.handleGrandChildFilter({
        toDate: payloadValue.endDate,
        clinic_id: [this.state.clinicId],
      });
      localStorage.setItem("insight-sales-date", JSON.stringify(payloadValue));
      this.setState({ ...payloadValue, singleDateCalendar: false });
    }
  };

  render() {
    let selectionRange = {};
    selectionRange = {
      startDate: this.state.startDate ? this.state.startDate : new Date(),
      endDate: this.state.endDate ? this.state.endDate : new Date(),
      key: "selection",
    };
    let selectValues = {};
    selectValues = this.select2Value(options, this.state.filterName);

    return (
      <div className="mainRightActions mrrActions">
        {this.state.actionDetails &&
          this.state.actionDetails.showComissionFilter && (
            <div className="selectFilter">
              <Select
                onChange={this.handleSelect2Change}
                value={selectValues}
                options={options}
                name={`report_type`}
                styles={colourStyles}
                isSearchable
                isMulti={false}
              />
            </div>
          )}
        {this.state.actionDetails &&
          this.state.actionDetails.canShowLocFilter && (
            <span className="easy-link no-padding" onClick={this.toggleClinic}>
              <i className="fa fa-map-marker-alt"></i> {this.state.clinicName}
              <div
                className={
                  this.state.showClinics
                    ? "actionDropdownOuter clinicDropdown"
                    : "no-display"
                }
                ref={(clinicNode) => {
                  this.clinicNode = clinicNode;
                }}
              >
                <Scrollbars
                  style={{ minHeight: "200px" }}
                  className="full-width"
                  renderTrackHorizontal={(props) => (
                    <div
                      {...props}
                      style={{ display: "none" }}
                      className="track-horizontal"
                    />
                  )}
                >
                  <ul className="actionDropdown">
                    <li>
                      <a
                        onClick={this.changeClinic.bind(this, 0)}
                        className={this.state.clinicId == 0 ? "selected" : ""}
                      >
                        {this.state.languageData.bi_all_clinics}
                      </a>
                    </li>
                    {this.props.clinicList &&
                      this.props.clinicList.map((obj) => {
                        return (
                          <li key={"clinic-" + obj.id} data-clinic-id={obj.id}>
                            <a
                              onClick={this.changeClinic.bind(this, obj.id)}
                              className={
                                this.state.clinicId == obj.id ? "selected" : ""
                              }
                            >
                              {obj.clinic_name}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </Scrollbars>
              </div>
            </span>
          )}

        {this.state.actionDetails &&
          this.state.actionDetails.canShowCalFilter && (
            <a
              className="easy-link no-padding"
              ref={(node) => {
                this.node = node;
              }}
            >
              <i className="fa fa-calendar-alt"></i>
              {this.state.showCalendar && (
                <DateRangePicker
                  value={selectionRange}
                  className={"CalendarPreviewArea"}
                  ranges={[selectionRange]}
                  onChange={this.dateRangeOnChange}
                  maxDate={new Date()}
                  dragSelectionEnabled={false}
                />
              )}
              <input
                type="text"
                className="b-s-sales sales-filter-cal input-cal setting-search-input"
                name="calendar-input"
                value={
                  showFormattedDate(this.state.fromDate) +
                  ` - ` +
                  showFormattedDate(this.state.toDate)
                }
                readOnly={true}
              />
            </a>
          )}
        {this.state?.actionDetails?.showSingleDateFilter && (
          <a className="easy-link no-padding">
            <div className="d-flex gap-4">
              As of <i className="fa fa-calendar-alt m-t-3 "></i>
              <div className="m-l-0 border-top border-bottom border-right text-center">
                <DatePicker
                  onInputClick={() => this.handleSingleDateChange(true)}
                  open={this.state.singleDateCalendar}
                  selected={moment(this.state.endDate).toDate()}
                  onChange={(date) => this.handleSelectDate(date)}
                  maxDate={new Date()}
                  onClickOutside={() => this.handleSingleDateChange(false)}
                  isClearable
                  clearButtonClassName="react-datepicker__close-icon"
                />
              </div>
            </div>
          </a>
        )}

        {this.state.actionDetails &&
          this.state.actionDetails.canShowPayPeriodFilter && (
            <div className="export pull-right p-l-10">
              <span className="search-text m-r-5 no-padding-left">
                {"Pay Period"}:
              </span>
              <div className="dropdown pull-left churnFilter">
                <button
                  className="btn btn-default dropdown-toggle"
                  type="button"
                  id="dropdownMenuPayPeriod"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.state.pay_period_filter_label}
                  <i className="fa fa-calendar-alt"></i>
                </button>
                {this.state.pay_period_options &&
                  this.state.pay_period_options.length > 0 && (
                    <ul
                      className="dropdown-menu "
                      aria-labelledby="dropdownMenuPayPeriod"
                    >
                      {this.state.pay_period_options.map((obj, idx) => {
                        return (
                          <li key={idx}>
                            <a
                              onClick={this.handlePayPeriodFilter.bind(
                                this,
                                obj,
                              )}
                              className={
                                this.state.pay_period_filter == obj.slug
                                  ? "selected"
                                  : ""
                              }
                            >
                              {obj.label}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
              </div>
            </div>
          )}

        {this.state.actionDetails &&
          this.state.actionDetails.canShowMonthFilter && (
            <FilterMonth
              selectedMonth={this.state.month}
              selectedYear={this.state.year}
              handleFilterMonth={this.handleFilterMonth}
              handleChildState={this.handleChildState}
              className={"calendar-btn cal-date-btn pull-right p-l-10 mrrCal"}
            />
          )}

        {this.state.actionDetails &&
          this.state.actionDetails.canShowChurnFilter && (
            <div className="export pull-right p-l-20">
              <span className="search-text m-r-5 no-padding-left">
                {this.state.languageData.bi_churned_reason}:
              </span>
              <div className="dropdown pull-left churnFilter">
                <button
                  className="btn btn-default dropdown-toggle"
                  type="button"
                  id="dropdownMenu1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.state.churn_filter == "manual"
                    ? this.state.languageData.bi_cancelled_by_patient_or_staff
                    : this.state.churn_filter == "payment_failed"
                    ? this.state.languageData.bi_cancelled_by_payment_decline
                    : this.state.languageData.bi_all}
                  <i className="fas fa-angle-down"></i>
                </button>
                <ul className="dropdown-menu " aria-labelledby="dropdownMenu1">
                  <li>
                    <a
                      href="javascript:void(0);"
                      name="both"
                      onClick={this.handleChurnedReason.bind(this, "both")}
                    >
                      {this.state.languageData.bi_all}
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0);"
                      name="manual"
                      onClick={this.handleChurnedReason.bind(this, "manual")}
                    >
                      {this.state.languageData.bi_cancelled_by_patient_or_staff}
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0);"
                      name="payment_failed"
                      onClick={this.handleChurnedReason.bind(
                        this,
                        "payment_failed",
                      )}
                    >
                      {this.state.languageData.bi_cancelled_by_payment_decline}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}

        {this.state.actionDetails && this.state.actionDetails.showMrrFilter && (
          <div className="export pull-right p-l-10">
            <span className="search-text m-r-5 no-padding-left">Type:</span>
            <div className="dropdown pull-left churnFilter">
              <button
                className="btn btn-default dropdown-toggle"
                type="button"
                id="dropdownMenu12"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {this.state.tierName}
                <i className="fas fa-angle-down"></i>
              </button>
              <ul className="dropdown-menu " aria-labelledby="dropdownMenu12">
                <li>
                  <a
                    href="javascript:void(0);"
                    onClick={this.handleTierChange.bind(this, -1)}
                  >
                    {this.state.languageData.bi_all}
                  </a>
                </li>
                {this.props.parentState &&
                  this.props.parentState.reportData &&
                  this.props.parentState.reportData.tier_list &&
                  this.props.parentState.reportData.tier_list.length > 0 &&
                  this.props.parentState.reportData.tier_list.map(
                    (obj, idx) => {
                      return (
                        <li key={idx}>
                          <a
                            href="javascript:void(0);"
                            onClick={this.handleTierChange.bind(
                              this,
                              obj.tier_id,
                            )}
                          >
                            {obj.tier_name}
                          </a>
                        </li>
                      );
                    },
                  )}
              </ul>
            </div>
          </div>
        )}

        {false &&
          this.state.actionDetails &&
          this.state.actionDetails.showMrrFilter && (
            <span className="search-text m-r-5 no-padding-left no-display">
              Total Active Members -{" "}
              {this.props.parentState &&
                this.props.parentState.reportData &&
                this.props.parentState.reportData.total}
            </span>
          )}
        {this.state.actionDetails &&
          this.state.actionDetails.canShowDownloadBtn && (
            <a
              className="easy-link no-padding"
              onClick={() => this.handleDownload("xls")}
            >
              <i className="fa fa-download"></i> Download Excel
            </a>
          )}
      </div>
    );
  }
}

export default withRouter(SalesActions);
