import React from "react";
import cx from "clsx";
import * as yup from "yup";
import { useFormik } from "formik";
import classes from "../scss/repeatMd.module.scss";
import styles from "../scss/RepeatMdEnrollmentConfiguration.module.scss";
import { AngleDownIcon } from "../../../../../assets/Icons/AngleDownIcon";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { Input } from "../../../../../shared/Input/Input";
import { Textarea } from "../../../../../shared/Textarea/Textarea";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";
import { Button } from "../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { tCommon } from "../../../../../i18n/useAppTranslation";
import { useStoreEnrollment } from "../hooks/useStoreEnrollment";
import { InputError } from "../../../../../shared/InputError/InputError";
import { uiNotification } from "../../../../../services/UINotificationService";

const RepeatMdEnrollmentConfiguration = ({ data, expanded, setExpanded }) => {
  const initialValues = {
    clientEnrollmentLink: data.enrollmentLink || "",
    clientInvitationSms: data.enrollmentSmsText || "",
  };

  const schema = yup.object().shape({
    clientEnrollmentLink: yup.string().required("Required"),
    clientInvitationSms: yup.string().required("Required"),
  });

  const { mutate, isLoading } = useStoreEnrollment({
    onSuccess: (data) => uiNotification.success(data.data.message),
  });

  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    validationSchema: schema,
    initialValues: initialValues || {},
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate({
        enrollment_link: values.clientEnrollmentLink,
        enrollment_sms_text: values.clientInvitationSms,
      });
    },
  });

  return (
    <div
      className={cx(
        classes.expandButtonContainer,
        expanded.enrollmentConfiguration && classes.active,
      )}
    >
      <button
        className={classes.expandListButton}
        onClick={() =>
          setExpanded({
            ...expanded,
            enrollmentConfiguration: !expanded.enrollmentConfiguration,
          })
        }
      >
        <span>RepeatMD Enrollment Invitation Configuration</span>
        <span
          className={cx(
            classes.expandIcon,
            expanded.enrollmentConfiguration && classes.expanded,
          )}
        >
          <AngleDownIcon width={14} height={14} fill={"#667680"} />
        </span>
      </button>
      {expanded.enrollmentConfiguration && (
        <div className={styles.enrollmentConfigurationContainer}>
          <div className={styles.enrollmentLink}>
            <InputLabel>Client Enrollment Link</InputLabel>
            <Input
              name="clientEnrollmentLink"
              value={values.clientEnrollmentLink}
              onChange={(e) =>
                setFieldValue("clientEnrollmentLink", e.target.value)
              }
            ></Input>
            {errors.clientEnrollmentLink && (
              <InputError className={styles.inputErrors}>
                {errors.clientEnrollmentLink}
              </InputError>
            )}
          </div>
          <div className={styles.invitationSms}>
            <InputLabel>Client Invitation Sms</InputLabel>
            <div className={styles.clientInvitationSms}>
              <div className={styles.invitationSmsTextarea}>
                <Textarea
                  name="clientInvitationSms"
                  value={values.clientInvitationSms}
                  onChange={(e) =>
                    setFieldValue("clientInvitationSms", e.target.value)
                  }
                  placeholder="[NAME], did you know?! We have our own VIP Rewards app. Sign up immediately to receive the best offers. Click here to start earning: [LINK]"
                  rows={7}
                  maxLength={1000}
                ></Textarea>
                {errors.clientInvitationSms && (
                  <InputError className={styles.inputErrors}>
                    {errors.clientInvitationSms}
                  </InputError>
                )}
                <span className={styles.charsLength}>
                  {values.clientInvitationSms.length}/1000
                </span>
              </div>
              <AlertBox className={styles.snippets}>
                <div className={styles.snippetsTitle}>Snippets</div>
                <div className={styles.snippetsContent}>
                  <span>{`[NAME]`}</span>
                  <span>{`[LINK]`}</span>
                </div>
              </AlertBox>
            </div>
          </div>
          <div className={classes.saveBtn}>
            <Button
              size="small"
              onClick={handleSubmit}
              className={classes.updateButton}
              isDisabled={isLoading}
              leftAdornment={
                isLoading ? (
                  <CircularProgress size="small" color="white" />
                ) : undefined
              }
            >
              {tCommon("label.save")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RepeatMdEnrollmentConfiguration;
