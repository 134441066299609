export const isPendingCosmeticProcedureCompleted = (procedureStatuses = {}) => {
  const {
    hasTraceability,
    hasNotes,
    hasConsent,
    hasQuestionnaire,
    hasPictures,
    hasPaymentTransaction,
  } = procedureStatuses;

  return (
    hasTraceability &&
    hasNotes &&
    hasConsent &&
    hasQuestionnaire &&
    hasPictures &&
    hasPaymentTransaction
  );
};

export const isPendingHealthProcedureCompleted = (procedureStatuses = {}) => {
  const { hasNotes, hasConsent, hasQuestionnaire } = procedureStatuses;
  return hasNotes && hasConsent && hasQuestionnaire;
};
