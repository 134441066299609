const nameInitialState = {
  action: "",
  isSmsNotificationsFetching: false,
  unread_sms_notification_count: 0,
};

const dashboard = (state = nameInitialState, action) => {
  switch (action.type) {
    case "RESET_ALL": {
      return {
        ...state,
        action: "RESET_ALL",
      };
    }
    case "USER_LOGS_LIST": {
      return { ...state, data: action.payload, action: "USER_LOGS_LIST" };
    }

    case "SMS_NOTIFICATION_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "SMS_NOTIFICATION_LIST",
      };
    }

    case "NOTIFICATION_LIST": {
      return { ...state, data: action.payload, action: "NOTIFICATION_LIST" };
    }

    case "DELETE_NOTIFICATION_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "DELETE_NOTIFICATION_DATA",
      };
    }
    case "EXPORT_CSV": {
      return { ...state, data: action.payload, action: "EXPORT_CSV" };
    }
    case "VIEW_CHANGES": {
      return { ...state, data: action.payload, action: "VIEW_CHANGES" };
    }

    case "FETCH_DASH_CLINICS": {
      return { ...state, data: action.payload, action: "FETCH_DASH_CLINICS" };
    }

    case "SELECTED_SMS_POPUPS": {
      return { ...state, data: action.payload, action: "SELECTED_SMS_POPUPS" };
    }

    case "FETCH_POPUPS_MENU": {
      return {
        ...state,
        data: action.payload,
        action: "FETCH_POPUPS_MENU",
        unassigned_efaxes_count: action.payload.data.unassigned_efaxes_count,
      };
    }

    case "FETCH_POPUPS_MENU_START": {
      return { ...state, isSmsNotificationsFetching: true };
    }
    case "FETCH_POPUPS_MENU_FINISH": {
      return { ...state, isSmsNotificationsFetching: false };
    }

    case "CREATE_REPLY": {
      return { ...state, data: action.payload, action: "CREATE_REPLY" };
    }

    case "EMPTY_DATA": {
      return { ...state, data: action.payload, action: "EMPTY_DATA" };
    }
    case "DASHBOARD_WIDGET_LIST": {
      return {
        ...state,
        data: action.payload,
        action: "DASHBOARD_WIDGET_LIST",
      };
    }
    case "SELECTED_WIDGETS_IDS": {
      return { ...state, data: action.payload, action: "SELECTED_WIDGETS_IDS" };
    }

    case "DASHBOARD_PROVIDER_SALES_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "DASHBOARD_PROVIDER_SALES_DATA",
      };
    }

    case "DASHBOARD_PROVIDER_GOALS_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "DASHBOARD_PROVIDER_GOALS_DATA",
      };
    }

    case "DASHBOARD_PRODUCT_SALES_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "DASHBOARD_PRODUCT_SALES_DATA",
      };
    }

    case "PROVIDER_NPS_SCORES": {
      return { ...state, data: action.payload, action: "PROVIDER_NPS_SCORES" };
    }
    case "CLINIC_NPS_SCORES": {
      return { ...state, data: action.payload, action: "CLINIC_NPS_SCORES" };
    }

    case "OFFICE_SALES_GOALS": {
      return { ...state, data: action.payload, action: "OFFICE_SALES_GOALS" };
    }

    case "CLINIC_SALES_DATA": {
      return { ...state, data: action.payload, action: "CLINIC_SALES_DATA" };
    }

    case "PROCEDURE_GOALS_DATA": {
      return { ...state, data: action.payload, action: "PROCEDURE_GOALS_DATA" };
    }

    case "MOST_USED_DISCOUNTS_DATA": {
      return {
        ...state,
        data: action.payload,
        action: "MOST_USED_DISCOUNTS_DATA",
      };
    }

    case "BOOKING_PER_CLINIC": {
      return { ...state, data: action.payload, action: "BOOKING_PER_CLINIC" };
    }

    case "BOOKING_PER_PROVIDER": {
      return { ...state, data: action.payload, action: "BOOKING_PER_PROVIDER" };
    }
    default:
      return state;
  }
};

export default dashboard;
