import React, { useMemo } from "react";
import cx from "clsx";
import styles from "../../ClientProfile/scss/clientProfile.module.scss";
import { Select } from "../../../../shared/Select/Select";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import { DateRangePicker } from "../../../../shared/DateRangePicker/DateRangePicker";
import { InputSearch } from "../../../../boxes/InputSearch/InputSearch";
import { Button } from "../../../../shared/Button/Button";
import { tCommon } from "../../../../i18n/useAppTranslation";
import { useChartingFilterCategoriesQuery } from "../../../../api/queries/useChartingFilterCategoriesQuery";
import { useProvidersQuery } from "../../../../api/queries/useProvidersQuery";

const ProcedureFilters = ({
  period,
  handlePeriod,
  categoryFilter,
  providerFilter,
  searchValue,
  onChangeSearch,
  handleResetFilters,
}) => {
  const { data: user } = useCurrentUserQuery();
  const { data: categories } = useChartingFilterCategoriesQuery();

  const categoriesOptions = useMemo(() => {
    return categories?.map((category) => {
      return { label: category.name, value: category.id };
    });
  }, [categories]);

  const providersQuery = useProvidersQuery();

  const providersOptions = useMemo(() => {
    return providersQuery.data?.data?.data?.map((provider) => {
      return { label: provider.full_name, value: provider.id };
    }) || [];
  }, [providersQuery.data?.data?.data]);

  return (
    <div className={styles.procedureFilters}>
      <InputSearch
        value={searchValue}
        onChange={(value) => onChangeSearch(value)}
        className={cx(styles.minHeightButtons, "w-sm-100")}
        placeholder="Procedure Name"
      />
      <DateRangePicker
        size="small"
        className={cx(styles.procedureDatePicker, "w-sm-100")}
        buttonClassName={cx(styles.minHeightButtons, styles.datePicker, "w-sm-100")}
        dateFormat={user?.dateFormat}
        onChange={({ startDate, endDate }) =>
          handlePeriod({ startDate, endDate })
        }
        value={{
          startDate: period.startDate,
          endDate: period.endDate,
        }}
      />
      <Select
        size="small"
        options={[{ label: "All Providers", value: "" }, ...providersOptions]}
        className={cx(styles.tabListSelects, "w-sm-100")}
        placeholder="Select Provider"
        value={providerFilter.value}
        onChange={(options) => providerFilter.update(options)}
        isSearchable
      />
      <Select
        isSearchable
        size="small"
        options={[
          { label: "All Categories", value: "" },
          ...(categoriesOptions || []),
        ]}
        className={cx(styles.tabListSelects, "w-sm-100")}
        placeholder="Select Category"
        value={categoryFilter.value}
        onChange={(options) => categoryFilter.update(options)}
      />
      <Button
        size="small"
        className={cx(styles.minHeightButtons, "w-sm-100")}
        onClick={handleResetFilters}
      >
        {tCommon("label.resetFilters")}
      </Button>
    </div>
  );
};

export default ProcedureFilters;
