import { CLIENT_GLOBAL_NAME } from "../../consts/api";
import { objectUtil } from "../../utilities/object";

var keysMap = {
  [CLIENT_GLOBAL_NAME.client]: "client",
  [CLIENT_GLOBAL_NAME.patient]: "patient",
};

var isMatchedObject = (translationObject) => {
  return (
    objectUtil.isObject(translationObject) &&
    "patient" in translationObject &&
    "client" in translationObject
  );
};

var updatePath_ = (path, alias, resources) => {
  const translationObject = objectUtil.get(resources, path);

  if (isMatchedObject(translationObject)) {
    return `${path}.${alias}`;
  }

  return path;
};

var updatePath = ({ path, i18n, ns, alias }) => {
  const lang = i18n.language;
  const resources = i18n.getDataByLanguage(lang)?.[ns] || {};

  if (typeof path === "string") {
    return updatePath_(path, keysMap[alias], resources);
  }

  if (Array.isArray(path)) {
    return path.map((p) => updatePath_(p, keysMap[alias], resources));
  }

  return path;
};

export var patientAliasEnhancer = { updatePath };
