import React from "react";
import { formatCurrency } from "../../../../utilities/general";

const RepeatMdRedemptions = ({ repeatMdRedemptionsItems }) => {
  return (
    <table
      border={0}
      cellPadding={10}
      cellSpacing={0}
      width={700}
      style={{ marginTop: "25px" }}
    >
      <tbody>
        <tr>
          <td
            style={{
              borderBottom: "1px solid #dddddd",
            }}
          >
            <div
              style={{
                fontSize: "13px",
                color: "#000000",
                textTransform: "uppercase",
                padding: "10px 0px",
              }}
            >
              Transferred from repeatmd wallet
            </div>
          </td>
          <td
            style={{
              borderBottom: "1px solid #dddddd",
            }}
            align="right"
          >
            <div
              style={{
                fontSize: "13px",
                color: "#000000",
                textTransform: "uppercase",
                textAlign: "right",
                padding: "10px 0px",
              }}
            >
              Amount
            </div>
          </td>
        </tr>
        {repeatMdRedemptionsItems.map((item) => (
          <tr key={item.id}>
            <td
              style={{
                borderBottom: "1px solid #dddddd",
              }}
            >
              <div
                style={{
                  fontSize: "14px",
                  color: "#777777",
                  padding: "10px 0px",
                }}
              >
                {`RepeatMD - ${item.foreign_wallet_item_name}`}
              </div>
            </td>
            <td
              align="right"
              style={{
                borderBottom: "1px solid #dddddd",
                padding: "10px 0px",
              }}
            >
              <div
                style={{
                  fontSize: "13px",
                  color: "#777777",
                  textTransform: "uppercase",
                  textAlign: "right",
                }}
              >
                {item.type === "invoice"
                  ? formatCurrency(item.rmd_redeem_price)
                  : `${item.quantity} unit(s)`}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RepeatMdRedemptions;
