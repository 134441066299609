export function isObjectInTranslation(translation, path) {
  if (typeof path === "string") {
    return (
      translation.includes("returned an object instead of string") &&
      translation.includes(path)
    );
  }

  if (Array.isArray(path)) {
    return (
      translation.includes("returned an object instead of string") &&
      path.some((p) => translation.includes(p))
    );
  }

  return false;
}
