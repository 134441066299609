import React from "react";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { DateRangePicker } from "../../../../../../shared/DateRangePicker/DateRangePicker";
import { ReportHeader } from "../../../../components/ReportHeader/ReportHeader";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import { Button } from "../../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { svg } from "../../../../../../assets/svg";

export function Header({ period, isReportsLoading, downloadExcel }) {
  const { tBi } = useAppTranslation.BusinessInsights();
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  return (
    <ReportHeader label={tBi("shopifyOrdersReport.label")}>
      <DateRangePicker
        isDisabled={isReportsLoading}
        size="small"
        dateFormat={user?.dateFormat}
        onChange={({ startDate, endDate }) => {
          period.update({
            from: startDate,
            to: endDate,
          });
        }}
        value={{
          startDate: period.value.from,
          endDate: period.value.to,
        }}
      />
      <Button
        size="small"
        variant="outlined"
        onClick={downloadExcel.initiate}
        isDisabled={downloadExcel.isLoading || isReportsLoading}
        leftAdornment={
          downloadExcel.isLoading ? (
            <CircularProgress size="small" />
          ) : (
            <img src={svg.downloadPrimary} alt="dl" width="20px" />
          )
        }
      >
        {tCommon("label.downloadExcel")}
      </Button>
    </ReportHeader>
  );
}
