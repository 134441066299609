import React, { useEffect, useMemo, useState } from "react";
import { Select } from "../../../../shared/Select/Select";
import { withRouter } from "react-router";
import { useUpdateChartCategory } from "../hooks/useUpdateChartCategory";
import { useChartingFilterCategoriesQuery } from "../../../../api/queries/useChartingFilterCategoriesQuery";

const FilterCategorizationSelect = ({
  value,
  procedureId,
  refetchProcedures,
}) => {
  const { data } = useChartingFilterCategoriesQuery();
  const { mutate, isLoading } = useUpdateChartCategory(procedureId, {
    onSuccess: () => refetchProcedures(),
  });

  const [selectedCategory, setSelectedCategory] = useState({
    label: "Uncategorized",
    value: 0,
  });

  const handleSelect = (selectedOption) => {
    setSelectedCategory(selectedOption);
    mutate({ charting_category_id: selectedOption.value });
  };

  const options = useMemo(() => {
    return data?.map((category) => ({
      label: category.name,
      value: category.id,
    }));
  }, [data]);

  useEffect(() => {
    const selectedValue = options?.find((option) => option.value === value);
    setSelectedCategory(selectedValue);
  }, [options, value]);

  return (
    <Select
      fullWidth
      isSearchable
      size="small"
      options={options}
      value={selectedCategory}
      onChange={(options) => handleSelect(options)}
      isLoading={isLoading}
      isDisabled={isLoading}
    />
  );
};

export default withRouter(FilterCategorizationSelect);
