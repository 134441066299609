import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../consts/api";

export const useRmdReserveValidate = (invoiceId, options = {}) => {
  return useQuery(
    ["rmdReserveValidate"],
    async () => {
      return http.post(HTTP_ENDPOINTS.rmdReserveValidate(invoiceId));
    },
    { cacheTime: 0, ...options },
  );
};
