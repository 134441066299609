import { useMemo } from "react";
import { useServicesWithRelatedConsentsAndQuestionnairesQuery } from "../../../../api/queries/useServicesWithRelatedConsentsAndQuestionnairesQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";

export function useServiceOptions({ appointmentServiceIds }) {
  const { tCommon } = useAppTranslation.Common();

  const { data, isLoading } =
    useServicesWithRelatedConsentsAndQuestionnairesQuery({
      onError: () => {
        uiNotification.error(tCommon("error.fetchServices"));
      },
    });

  const preparedData = useMemo(() => {
    return (
      data
        ?.filter((i) => !i.isDeleted)
        ?.map((i) => ({
          label: i.name,
          value: i.id,
          consentIds: i.consents.map((c) => c.id),
          questionnaireIds: i.questionnaires.map((c) => c.id),
        })) || []
    );
  }, [data]);

  const filteredData = useMemo(() => {
    if (!appointmentServiceIds) {
      return preparedData;
    }

    return preparedData.filter((i) => appointmentServiceIds.includes(i.value));
  }, [preparedData, appointmentServiceIds]);

  return {
    data: filteredData,
    isLoading,
  };
}
