import React, { useMemo, useState } from "react";
import moment from "moment";
import { WIDGET_NAMES } from "../../Dashboard.consts";
import { WidgetBox } from "../../boxes/WidgetBox/WidgetBox.js";
import { useRevenuePerHour } from "./hooks/useRevenuePerHour.js";
import RevenuePerHourChart from "./components/RevenuePerHourChart.js";
import { API_DATE_FORMAT } from "../../../../consts/api.js";
import { slicePaginationData } from "../../Dashboard.utils.js";

const ITEMS_PER_PAGE = 4;
const PAGINATION_RANGE = 5;

const WidgetRevenuePerHour = ({ fromReportData }) => {
  const [period, setPeriod] = useState({
    from: moment().startOf("month").toDate(),
    to: moment().endOf("month").toDate(),
  });

  const [activePage, setActivePage] = useState(1);

  const params = fromReportData || {
    from_date: moment(period.from).format(API_DATE_FORMAT),
    to_date: moment(period.to).format(API_DATE_FORMAT),
  };

  const { data: widgetData, isLoading: isWidgetDataLoading } =
    useRevenuePerHour(params, {
      select: (data) => data.data.data.data || [],
      enabled: Boolean(params.from_date),
    });

  const monthlyRange = !fromReportData
    ? {
        dates: {
          start: period.from,
          end: period.to,
        },
        update: setPeriod,
        isNextDisabled: moment(period.to).isSameOrAfter(moment(), "month"),
      }
    : null;

  const slicedReportData = useMemo(
    () =>
      slicePaginationData({
        activePage,
        itemsPerPage: ITEMS_PER_PAGE,
        data: widgetData,
      }),
    [widgetData, activePage],
  );

  const providerNames = useMemo(
    () => slicedReportData?.map((r) => r.provider_name),
    [slicedReportData],
  );

  const netRevenue = useMemo(
    () => slicedReportData?.map((r) => r.net_revenue_hour_raw),
    [slicedReportData],
  );

  const grossRevenue = useMemo(
    () => slicedReportData?.map((r) => r.gross_revenue_hour_raw),
    [slicedReportData],
  );

  const formattedNetRevenue = useMemo(
    () => slicedReportData?.map((r) => r.net_revenue_hour),
    [slicedReportData],
  );

  const formattedGrossRevenue = useMemo(
    () => slicedReportData?.map((r) => r.gross_revenue_hour),
    [slicedReportData],
  );

  const minutesWorked = useMemo(
    () => slicedReportData?.map((r) => r.minutes_worked_period),
    [slicedReportData],
  );

  const chartData = {
    providerName: providerNames || [],
    netRevenue: netRevenue || [],
    grossRevenue: grossRevenue || [],
    minutesWorked: minutesWorked || [],
    formattedNetRevenue: formattedNetRevenue || [],
    formattedGrossRevenue: formattedGrossRevenue || [],
  };

  return (
    <WidgetBox
      label={WIDGET_NAMES.revenue_per_hour}
      isLoading={isWidgetDataLoading}
      isEmpty={widgetData?.length === 0}
      headerClassName="flex justify-between items-center"
      monthlyRange={monthlyRange}
      pagination={{
        activePage,
        itemsCountPerPage: ITEMS_PER_PAGE,
        total: widgetData?.length,
        range: PAGINATION_RANGE,
        setActivePage,
      }}
    >
      <RevenuePerHourChart chartData={chartData} />
    </WidgetBox>
  );
};

export default WidgetRevenuePerHour;
