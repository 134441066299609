/* eslint-disable import/no-restricted-paths */

import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import UserReducer from "../_legacy/Reducers/UserReducer.js";
import SettingReducer from "../_legacy/Reducers/SettingReducer.js";
import ClinicReducer from "../_legacy/Reducers/ClinicReducer.js";
import AppointmentReducer from "../_legacy/Reducers/AppointmentReducer.js";
import consentsReducer from "../_legacy/Reducers/consentsReducer.js";
import DashboardReducer from "../_legacy/Reducers/DashboardReducer.js";
import ProcedureNotesReducer from "../_legacy/Reducers/ProcedureNotesReducer.js";
import surveyReducer from "../_legacy/Reducers/surveyReducer.js";
import InventoryReducer from "../_legacy/Reducers/InventoryReducer.js";
import ReportsReducer from "../_legacy/Reducers/ReportsReducer.js";
import ClientsReducer from "../_legacy/Reducers/ClientsReducer.js";
import ServiceReducer from "../_legacy/Reducers/ServiceReducer.js";
import TraceReducer from "../_legacy/Reducers/TraceReducer.js";
import SalesReducer from "../_legacy/Reducers/SalesReducer.js";
import ClientNotesReducer from "../_legacy/Reducers/ClientNotesReducer.js";
import InvoiceReducer from "../_legacy/Reducers/InvoiceReducer.js";
import TreatmentMarkingReducer from "../_legacy/Reducers/TreatmentMarkingReducer.js";
import ProcedureReducer from "../_legacy/Reducers/ProcedureReducer.js";
import AspireHcpFiltersReducer from "../_legacy/Reducers/AspireHcpFiltersReducer.js";
import SignUpReducer from "../_legacy/Reducers/SignUpReducer.js";
import AccountReducer from "../_legacy/Reducers/AccountReducer.js";
import DotPhraseReducer from "../_legacy/Reducers/DotPhraseReducer.js";
import CommonReducer from "../_legacy/Reducers/commonReducer.js";
import MembershipWalletReducer from "../_legacy/Reducers/MembershipWalletReducer.js";
import BusinessInsightReducer from "../_legacy/Reducers/BusinessInsightReducer.js";
import InboxReducer from "../_legacy/Reducers/InboxReducer.js";
import IntegrationReducer from "../_legacy/Reducers/IntegrationsReducer.js";
import ClockReducer from "../_legacy/Reducers/ClockReducer.js";
import ClearanceReducer from "../_legacy/Reducers/ClearanceReducer.js";
import VerticalsReducer from "../_legacy/Reducers/VerticalsReducer";
import CherryReducer from "../_legacy/Reducers/CherryReducer";
import KlarnaReducer from "../_legacy/Reducers/KlarnaReducer";
import StripeInfoUpdateReducer from "../_legacy/Reducers/StripeUpdateInfoReducer";
import AspireIntegrationReducer from "../_legacy/Reducers/AspireIntegrationsReducer";
import EvolusIntegrationReducer from "../_legacy/Reducers/EvolusIntegrationsReducer";
import LeadsReducer from "../_legacy/Reducers/LeadsReducer";
import CountrySuggestionsReducer from "../_legacy/Reducers/CountrySuggestionsReducer";
import { settingRecentlyDeletedPaperworkReducer } from "../_legacy/Reducers/settingRecentlyDeletedPaperworkReducer";
import AccountStatusRedirectReducer from "../_legacy/Reducers/AccountStatusRedirectReducer";
import CardreaderReducer from "../_legacy/Reducers/CardreaderReducer.js";
import LeadsAPIReducer from "../_legacy/Reducers/LeadsApiIntegrationReducer.js";
import StockAlertsReducer from "../_legacy/Reducers/StockAlertsReducer";
import ReferralSourceReducer from "../_legacy/Reducers/ReferralSourceReducer";
import StripeReducer from "../_legacy/Reducers/StripeReducer";

import { event } from "./event";
import { providerRoom } from "./providerRoom";
import { checkoutInvoice } from "./checkoutInvoice";
import { globalSearch } from "./globalSearch";
import { patientVitals } from "./patients/vitals";
import { betaFeatures } from "./betaFeatures/index.js";
import { globalSettings } from "./globalSettings/index.js";
import { inventoryProductsFilter } from "./inventoryFilter";
import { privacyPolicyPopup } from "./privacyPolicyPopup/index.js";
import { mutateProcedure } from "./patients/mutateProcedure/index.js";
import { introNotificationPopup } from "./introNotificationPopup";

export const rootReducer = combineReducers({
  [event.reducerName]: event.reducer,
  [providerRoom.reducerName]: providerRoom.reducer,
  [checkoutInvoice.reducerName]: checkoutInvoice.reducer,
  [globalSearch.reducerName]: globalSearch.reducer,
  [patientVitals.reducerName]: patientVitals.reducer,
  [betaFeatures.reducerName]: betaFeatures.reducer,
  [globalSettings.reducerName]: globalSettings.reducer,
  [inventoryProductsFilter.reducerName]: inventoryProductsFilter.reducer,
  [privacyPolicyPopup.reducerName]: privacyPolicyPopup.reducer,
  [mutateProcedure.reducerName]: mutateProcedure.reducer,
  [introNotificationPopup.reducerName]: introNotificationPopup.reducer,

  ClinicReducer,
  UserReducer,
  SettingReducer,
  AppointmentReducer,
  consentsReducer,
  DashboardReducer,
  ProcedureNotesReducer,
  surveyReducer,
  InventoryReducer,
  ReportsReducer,
  ClientsReducer,
  ServiceReducer,
  TraceReducer,
  SalesReducer,
  ClientNotesReducer,
  InvoiceReducer,
  TreatmentMarkingReducer,
  ProcedureReducer,
  SignUpReducer,
  AccountReducer,
  DotPhraseReducer,
  CommonReducer,
  MembershipWalletReducer,
  BusinessInsightReducer,
  InboxReducer,
  IntegrationReducer,
  ClockReducer,
  ClearanceReducer,
  VerticalsReducer,
  CherryReducer,
  KlarnaReducer,
  StripeInfoUpdateReducer,
  AspireIntegrationReducer,
  CountrySuggestionsReducer,
  AspireHcpFiltersReducer,
  EvolusIntegrationReducer,
  LeadsReducer,
  StockAlertsReducer,
  AccountHoldRedirectReducer: AccountStatusRedirectReducer,
  routing: routerReducer,
  settingRecentlyDeletedPaperworkReducer,
  CardreaderReducer,
  LeadsAPIReducer,
  ReferralSourceReducer,
  StripeReducer,
});
