import React, { useMemo } from "react";
import cx from "clsx";
import { useFormik } from "formik";
import { AngleDownIcon } from "../../../../../assets/Icons/AngleDownIcon";
import ClinicRow from "./ClinicRow";
import classes from "../scss/repeatMd.module.scss";
import { Button } from "../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";

const RepeatMdClinicSync = ({
  expanded,
  setExpanded,
  data,
  isSaving,
  mutate,
}) => {
  const { tCommon } = useAppTranslation.Common();
  const { tSettings } = useAppTranslation.Settings();

  const initialValues = useMemo(
    () =>
      data?.clinics.reduce((acc, clinic) => {
        return { ...acc, [clinic.clinicId]: clinic.locationId };
      }, {}),
    [data],
  );

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: initialValues || {},
    enableReinitialize: true,
    onSubmit: (values) => {
      const valuesToSave = Object.entries(values).map(
        ([clinicId, locationId]) => ({
          clinicId: clinicId,
          locationId: locationId,
        }),
      );
      mutate(
        {
          isActive: true,
          clinics: valuesToSave,
        },
        {
          onSuccess: () =>
            uiNotification.success(
              tSettings("repeatMdIntegration.locationSave.success"),
            ),
        },
      );
    },
  });

  return (
    <div
      className={cx(
        classes.expandButtonContainer,
        expanded.clinicSync && classes.active,
      )}
    >
      <button
        className={classes.expandListButton}
        onClick={() =>
          setExpanded({
            ...expanded,
            clinicSync: !expanded.clinicSync,
          })
        }
      >
        <span>RepeatMD & Aesthetic Record Clinic Sync</span>
        <span
          className={cx(
            classes.expandIcon,
            expanded.clinicSync && classes.expanded,
          )}
        >
          <AngleDownIcon width={14} height={14} fill={"#667680"} />
        </span>
      </button>
      {expanded.clinicSync && (
        <>
          {data?.clinics.map((clinic) => (
            <ClinicRow
              key={clinic.clinicId}
              clinicId={clinic.clinicId}
              clinicName={clinic.clinicName}
              locations={data.repeatMdLocations}
              values={values}
              setFieldValue={setFieldValue}
            />
          ))}
          <div className={classes.saveBtn}>
            <Button
              size="small"
              onClick={handleSubmit}
              className={classes.updateButton}
              isDisabled={isSaving}
              leftAdornment={
                isSaving ? (
                  <CircularProgress size="small" color="white" />
                ) : undefined
              }
            >
              {tCommon("label.save")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default RepeatMdClinicSync;
