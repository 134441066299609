import React from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { isValidPhoneNumber } from "libphonenumber-js";
import { withRouter } from "react-router";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { tCommon, tSales } from "../../../../../i18n/useAppTranslation";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useChargeForm } from "../../hooks/useChargeForm";
import "react-phone-input-2/lib/style.css";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { uiNotification } from "../../../../../services/UINotificationService";
import { usePaymentFlow } from "../../hooks/usePaymentFlow";
import PaymentFormModal from "./components/PaymentFormModal";
import PaymentTipsModal from "./components/PaymentTipsModal";

const ViewChargeText2Pay = ({ history, isTipsApplying }) => {
  const { invoice, amountToPay } = useInvoice();
  const { chargeText2Pay } = useInvoiceActions();
  const { showTipsModal } = usePaymentFlow();

  const goToInvoice = () => {
    const invoiceId = invoice?.id;
    const patientId = invoice?.patient?.id;

    history.push(`/sales/invoice/${invoiceId}/${patientId}/invoices`);
  };

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay,
      currency: invoice?.currency,
    }),
    phoneNumber: yup
      .string()
      .required(tSales("checkoutInvoice.formError.phoneNumberRequired"))
      .test(
        "valid-phone-number",
        tSales("checkoutInvoice.formError.validPhoneNumberRequired"),
        function (value) {
          return isValidPhoneNumber(
            value || "",
            this.parent.country?.countryCode.toUpperCase(),
          );
        },
      ),
    email: yup
      .string()
      .email(tCommon("formError.emailInvalid"))
      .required(tSales("checkoutInvoice.formError.emailRequired")),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: () => {
        text2PayPayment();
      },
      initialValues: {
        amount: amountToPay || "",
        phoneNumber: invoice?.patient?.phoneNumber || "",
        email: invoice?.patient?.email || "",
      },
    });

  const text2PayPayment = () => {
    chargeText2Pay
      .initiate({
        amount: amountToPay,
        email: form.email,
        phoneNumber: form.phoneNumber,
      })
      .then((response) => {
        uiNotification.success(response?.data?.message);
        goToInvoice();
      })
      .catch((e) => {
        uiNotification.error(e?.response?.data?.message);
      })
      .finally(() => {
        dispatch(checkoutInvoice.actions.paymentViewClose());
      });
  };

  return !showTipsModal.value ? (
    <PaymentFormModal
      isLoading={chargeText2Pay.isLoading}
      isValid={isValid}
      isTipsApplying={isTipsApplying}
      submit={submit}
      form={form}
      errors={errors}
      hasError={hasError}
      changeFormValue={changeFormValue}
      showTipsModal={showTipsModal}
    />
  ) : (
    <PaymentTipsModal
      isTipsApplying={isTipsApplying}
      totalAmountToPay={amountToPay}
      showTipsModal={showTipsModal}
      submit={submit}
      isLoading={chargeText2Pay.isLoading}
    />
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
});

export default withRouter(connect(mapStateToProps)(ViewChargeText2Pay));
