import { positionFooterCorrectly } from "../../Utils/services.js";
import { uiNotification } from "../../services/UINotificationService.js";
import { tInventory } from "../../i18n/useAppTranslation.js";
import { http } from "../../services/HttpService.js";

const languageData = JSON.parse(localStorage.getItem("languageData"));

positionFooterCorrectly();

export const fetchInventoryData = (status, formData) => {
  const reducerActionType =
    status == "active"
      ? "INVENTORY_PRODUCT_LIST_ACTIVE"
      : status == "all"
      ? "INVENTORY_PRODUCT_LIST_CATEGORY"
      : "INVENTORY_PRODUCT_LIST_INACTIVE";

  const isInactive = status === "inactive";

  const endpoint = isInactive
    ? `super_products/${status}`
    : `inventory/products/${status}?scopes=category`;

  let data = formData;

  if (isInactive) {
    data = {
      params: {
        page: formData.params.page,
        pagesize: formData.params.pagesize,
        sortorder: formData.params.sortorder,
        term: formData.params.term,
        filter_by_category_id: formData.params.filter_by_category_id,
      },
    };
  }

  return (dispatch) => {
    http
      .get(endpoint, data)
      .then((response) => {
        dispatch({ type: reducerActionType, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: reducerActionType, payload: error.response.data });
      });
  };
};

export const fetchCategoriesData = (formData) => {
  return (dispatch) => {
    http
      .get("inventory/product_categories", formData)
      .then((response) => {
        dispatch({ type: "CATEGORY_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CATEGORY_LIST", payload: error.response.data });
      });
  };
};

export const fetchDiscountPackagesData = (formData) => {
  return async (dispatch) => {
    return http
      .get("discount_packages", formData)
      .then((response) => {
        dispatch({ type: "PACKAGES_LIST", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "PACKAGES_LIST", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
};

export const fetchDiscountGroupData = (formData) => {
  return (dispatch) => {
    http
      .get("discount_groups", formData)
      .then((response) => {
        dispatch({ type: "DISCOUNT_GROUP_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DISCOUNT_GROUP_LIST", payload: error.response.data });
      });
  };
};

export const fetchEGiftCardData = (formData) => {
  return (dispatch) => {
    http
      .get("egift_cards", formData)
      .then((response) => {
        dispatch({ type: "EGIFT_CARD_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "EGIFT_CARD_LIST", payload: error.response.data });
      });
  };
};

export const updateEGiftCard = (formData, egiftId) => {
  return (dispatch) => {
    http
      .put("egift_cards/" + egiftId, formData)
      .then((response) => {
        dispatch({ type: "UPDATE_EGIFTCARD", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_EGIFTCARD", payload: error.response.data });
      });
  };
};

export const createEGiftCard = (formData) => {
  return (dispatch) => {
    http
      .post("egift_cards", formData)
      .then((response) => {
        dispatch({ type: "CREATE_EGIFTCARD", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_EGIFTCARD", payload: error.response.data });
      });
  };
};

export const deleteEGiftCard = (egiftId) => {
  return (dispatch) => {
    http
      .delete("egift_cards/" + egiftId)
      .then((response) => {
        dispatch({ type: "DELETE_EGIFTCARD", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_EGIFTCARD", payload: error.response.data });
      });
  };
};

export const fetchEGiftCardDataID = (eGiftId) => {
  return (dispatch) => {
    http
      .get("egift_cards/" + eGiftId)
      .then((response) => {
        dispatch({ type: "FETCH_SELECTED_EGIFTCARD", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_SELECTED_EGIFTCARD",
          payload: error.response.data,
        });
      });
  };
};

export const createDiscountGroup = (formData) => {
  return (dispatch) => {
    http
      .post("discount_groups/add", formData)
      .then((response) => {
        dispatch({ type: "CREATE_DISCOUNT_GROUP", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_DISCOUNT_GROUP",
          payload: error.response.data,
        });
      });
  };
};

export const fetchDiscountGroupDataId = (eGiftId) => {
  return (dispatch) => {
    http
      .get("discount_groups/" + eGiftId)
      .then((response) => {
        dispatch({
          type: "FETCH_SELECTED_DISCOUNT_GROUP",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_SELECTED_DISCOUNT_GROUP",
          payload: error.response.data,
        });
      });
  };
};

export const updateDiscountGroup = (formData, discountGroupId) => {
  return (dispatch) => {
    let endPoint = "discount_group/add/0";
    if (discountGroupId > 0) {
      endPoint = "discount_group/edit/" + discountGroupId;
    }
    http
      .post(endPoint, formData)
      .then((response) => {
        dispatch({ type: "UPDATE_DISCOUNT_GROUP", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_DISCOUNT_GROUP",
          payload: error.response.data,
        });
      });
  };
};

export const deleteDiscountGroup = (discountGroupId) => {
  return (dispatch) => {
    http
      .delete("discount_groups/" + discountGroupId)
      .then((response) => {
        dispatch({ type: "DELETE_DISCOUNT_GROUP", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_DISCOUNT_GROUP",
          payload: error.response.data,
        });
      });
  };
};

export const addDiscountGroupProduct = (formData, discountGroupId) => {
  return (dispatch) => {
    http
      .post("discount_groups/add-product/" + discountGroupId, formData)
      .then((response) => {
        dispatch({
          type: "ADD_DISCOUNT_GROUP_PRODUCT",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "ADD_DISCOUNT_GROUP_PRODUCT",
          payload: error.response.data,
        });
      });
  };
};

export const deleteDiscountGroupProduct = (formData, discountGroupId) => {
  return (dispatch) => {
    http
      .delete("discount_groups/delete-product/" + discountGroupId, formData)
      .then((response) => {
        dispatch({
          type: "DELETE_DISCOUNT_GROUP_PRODUCT",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_DISCOUNT_GROUP_PRODUCT",
          payload: error.response.data,
        });
      });
  };
};

export const createCategory = (formData) => {
  return (dispatch) => {
    http
      .post("product_categories", formData)
      .then((response) => {
        dispatch({ type: "CREATE_CATEGORY", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_CATEGORY", payload: error.response.data });
      });
  };
};

export const fetchClinics = () => {
  return (dispatch) => {
    http
      .get("list_clinics")
      .then((response) => {
        dispatch({ type: "FETCH_CLINICS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "FETCH_CLINICS", payload: error.response });
      });
  };
};

export const fetchCategoriesDataID = (categoriesId) => {
  return (dispatch) => {
    http
      .get("product_categories/" + categoriesId)
      .then((response) => {
        dispatch({ type: "FETCH_SELECTED_CATEGORY", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_SELECTED_CATEGORY",
          payload: error.response.data,
        });
      });
  };
};

export const updateCategories = (formData, categoriesId) => {
  return (dispatch) => {
    http
      .put("product_categories/" + categoriesId, formData)
      .then((response) => {
        dispatch({ type: "UPDATE_CATEGORIES", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_CATEGORIES", payload: error.response.data });
        uiNotification.error(
          languageData[error.response.data.message] ||
            tInventory(
              `updatingCategory.apiError.${error.response.data.message}`,
            ),
        );
      });
  };
};

export const deleteCategories = (categoriesId) => {
  return (dispatch) => {
    http
      .delete("product_categories/" + categoriesId)
      .then((response) => {
        dispatch({ type: "DELETE_CATEGORIES", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_CATEGORIES", payload: error.response.data });
      });
  };
};

export const deactivateAllCat = () => {
  return (dispatch) => {
    http
      .post("product_categories/deactivate-all", {})
      .then((response) => {
        dispatch({ type: "DEACTIVATE_ALL_CATEGORIES", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DEACTIVATE_ALL_CATEGORIES",
          payload: error.response.data,
        });
      });
  };
};

export const getProductDefaultData = (productId) => {
  return (dispatch) => {
    http
      .get("products/" + productId)
      .then((response) => {
        dispatch({ type: "PRODUCT_DEFAULT_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "PRODUCT_DEFAULT_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const isProductNameAvailable = (formData) => {
  return (dispatch) => {
    http
      .post("product/is-product-available", formData)
      .then((response) => {
        dispatch({ type: "IS_PRODUCT_AVAILABLE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "IS_PRODUCT_AVAILABLE",
          payload: error.response.data,
        });
      });
  };
};

export const validateBundleProductTax = async (formData) => {
  return await http.post("validate-bundle-products-tax", formData);
};

export const addProduct = (formData) => {
  return (dispatch) => {
    http
      .post("products/" + formData.productId, formData)
      .then((response) => {
        dispatch({ type: "PRODUCT_ADDED", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "PRODUCT_ADDED", payload: error.response.data });
        uiNotification.error(
          languageData[error.response.data.message] ||
            tInventory(
              `updatingProduct.apiError.${error.response.data.message}`,
            ),
        );
      });
  };
};

export const deleteProduct = (id) => {
  return (dispatch) => {
    http
      .delete("products/" + id)
      .then((response) => {
        dispatch({ type: "PRODUCT_DELETED", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "PRODUCT_DELETED", payload: error.response.data });
      });
  };
};

export const resetAction = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_ACTION" });
  };
};

export const exportData = (formData, mode) => {
  return (dispatch) => {
    http
      .get("product_categories/export/" + mode, formData)
      .then((response) => {
        dispatch({ type: "EXPORT_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "EXPORT_DATA", payload: error.response.data });
      });
  };
};

export const exportProducts = (formData, mode) => {
  return (dispatch) => {
    http
      .get(
        "inventory/export-products/" +
          mode +
          "?scopes=category,productPricePerClinic",
        formData,
      )
      .then((response) => {
        dispatch({ type: "EXPORT_PRODUCT_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "EXPORT_PRODUCT_DATA", payload: error.response.data });
      });
  };
};

export const getDefaultPackageData = (packageId) => {
  return (dispatch) => {
    http
      .get("discount_packages/" + packageId)
      .then((response) => {
        dispatch({ type: "DEFAULT_PACKAGE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DEFAULT_PACKAGE_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const saveDiscountPackage = (formData) => {
  return async (dispatch) => {
    if (!formData.packageId) {
      return http
        .post("discount_packages/" + formData.packageId, formData)
        .then((response) => {
          dispatch({ type: "SAVE_PACKAGE_DATA", payload: response.data });
          return response.data;
        })
        .catch((error) => {
          dispatch({ type: "SAVE_PACKAGE_DATA", payload: error.response.data });
          return Promise.reject(error.response.data);
        });
    } else {
      return http
        .put("discount_packages/" + formData.packageId, formData)
        .then((response) => {
          dispatch({ type: "SAVE_PACKAGE_DATA", payload: response.data });
          return response.data;
        })
        .catch((error) => {
          dispatch({ type: "SAVE_PACKAGE_DATA", payload: error.response.data });
          return Promise.reject(error.response.data);
        });
    }
  };
};

export const uploadImageBookingPortal = (formData) => {
  return (dispatch) => {
    dispatch({ type: "SAVE_BOOKING_IMAGE_LOADING" });
    http
      .post(
        "media/upload?upload_type=online_booking_promotions_image",
        formData,
      )
      .then((response) => {
        dispatch({
          type: "SAVE_BOOKING_IMAGE_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_BOOKING_IMAGE_ERROR",
          payload: error.response.data,
        });
      });
  };
};

const serialize = function (obj) {
  var str = [];

  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }

  return str.join("&");
};

export const searchProductByName = (formData, mode = "") => {
  return (dispatch) => {
    http
      .get("discount-packages/search-product?" + serialize(formData.params))
      .then((response) => {
        let action = "";
        if (mode == "bogo_product") {
          action = "BOGO_PRODUCT_LIST";
        } else if (mode == "bogo_free_product") {
          action = "BOGO_FREE_PRODUCT_LIST";
        } else if (mode == "package") {
          action = "PACKAGE_PRODUCT_LIST";
        } else {
          action = "AUTO_PRODUCT_SEARCH_LIST";
        }
        dispatch({ type: action, payload: response.data });
      })
      .catch((error) => {
        let action = "";
        if (mode == "bogo_product") {
          action = "BOGO_PRODUCT_LIST";
        } else if (mode == "bogo_free_product") {
          action = "BOGO_FREE_PRODUCT_LIST";
        } else if (mode == "package") {
          action = "PACKAGE_PRODUCT_LIST";
        }
        dispatch({ type: action, payload: error.response.data });
      });
  };
};

export const emptyInventoryReducer = () => {
  return (dispatch) => {
    dispatch({ type: "EMPTY_INVENTROY" });
  };
};

export const deleteDiscountPackage = (diiscountPackageId) => {
  return (dispatch) => {
    http
      .delete("discount_packages/" + diiscountPackageId)
      .then((response) => {
        dispatch({ type: "DELETE_DISCOUNT_PACKAGE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_DISCOUNT_PACKAGE",
          payload: error.response.data,
        });
      });
  };
};

export const activateDiscountPackage = (diiscountPackageId) => {
  return (dispatch) => {
    http
      .get("discount-packages/activate/" + diiscountPackageId)
      .then((response) => {
        dispatch({ type: "ACTIVATE_DISCOUNT_PACKAGE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "ACTIVATE_DISCOUNT_PACKAGE",
          payload: error.response.data,
        });
      });
  };
};

export const activateProduct = (formData) => {
  return (dispatch) => {
    http
      .post("super_product/activate", formData)
      .then((response) => {
        dispatch({ type: "ACTIVATE_PRODUCT", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "ACTIVATE_PRODUCT", payload: error.response.data });
      });
  };
};

export const fetchPOSButtonData = (formData) => {
  return (dispatch) => {
    http
      .get("pos-quick-button", formData)
      .then((response) => {
        dispatch({ type: "POS_BUTTON_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "POS_BUTTON_LIST", payload: error.response.data });
      });
  };
};

export const getProductPackage = (formData) => {
  return (dispatch) => {
    http
      .get("pos-quick-buttons/search?" + serialize(formData.params))
      .then((response) => {
        dispatch({ type: "FETCH_PRODUCT_PACKAGE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_PRODUCT_PACKAGE",
          payload: error.response.data,
        });
      });
  };
};

export const fetchPOSButtonDataId = (posButtonId) => {
  return (dispatch) => {
    http
      .get("pos-quick-button/" + posButtonId)
      .then((response) => {
        dispatch({ type: "FETCH_POS_BUTTON_ID", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "FETCH_POS_BUTTON_ID", payload: error.response.data });
      });
  };
};

export const checkButtonName = (formData) => {
  return (dispatch) => {
    http
      .post("pos-quick-buttons/is-pos-quick-button-available", formData)
      .then((response) => {
        dispatch({ type: "CHECK_BUTTON_NAME", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CHECK_BUTTON_NAME", payload: error.response.data });
      });
  };
};

export const deletePOSButton = (posButtonId) => {
  return (dispatch) => {
    http
      .delete("pos-quick-button/" + posButtonId)
      .then((response) => {
        dispatch({ type: "DELETE_POS_BUTTON", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_POS_BUTTON", payload: error.response.data });
      });
  };
};

export const deleteTablePOSButton = (posButtonId, formData) => {
  return (dispatch) => {
    http
      .post("pos-quick-button/delete-product/" + posButtonId, formData)
      .then((response) => {
        dispatch({ type: "DELETE_POS_BUTTON_TABLE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_POS_BUTTON_TABLE",
          payload: error.response.data,
        });
      });
  };
};

export const fetchDiscountCoupons = (formData) => {
  return async (dispatch) => {
    return http
      .get("discount-coupons", formData)
      .then((response) => {
        dispatch({ type: "DISCOUNT_COUPONS_LIST", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "DISCOUNT_COUPONS_LIST",
          payload: error.response.data,
        });
        return Promise.reject(error.response?.data);
      });
  };
};

export const fetchPOSButtonActiveDeactive = (formData) => {
  return (dispatch) => {
    http
      .post("pos-quick-buttons/activate-deactivate-button", formData)
      .then((response) => {
        dispatch({ type: "POS_ACTIVATE_DEACTIVATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "POS_ACTIVATE_DEACTIVATE",
          payload: error.response.data,
        });
      });
  };
};
export const createTablePOSButton = (formData, tabId) => {
  return (dispatch) => {
    http
      .post("pos-quick-button/add-product/" + tabId, formData)
      .then((response) => {
        dispatch({ type: "CREATE_POS_TABLE_BUTTON", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_POS_TABLE_BUTTON",
          payload: error.response.data,
        });
      });
  };
};

export const createFetchPOSButton = (formData) => {
  return (dispatch) => {
    http
      .post("pos-quick-button/0", formData)
      .then((response) => {
        dispatch({ type: "CREATE_POS_BUTTON", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_POS_BUTTON", payload: error.response.data });
      });
  };
};

export const createDiscountCouponsId = (formData) => {
  return async (dispatch) => {
    return http
      .post("discount-coupons", formData)
      .then((response) => {
        dispatch({
          type: "CREATE_DISCOUNT_COUPONS_ID",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_DISCOUNT_COUPONS_ID",
          payload: error.response.data,
        });
        return Promise.reject(error.response?.data);
      });
  };
};

export const updateFetchPOSButton = (formData, posButId) => {
  return (dispatch) => {
    http
      .put("pos-quick-button/" + posButId, formData)
      .then((response) => {
        dispatch({ type: "UPDATE_POS_BUTTON", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_POS_BUTTON", payload: error.response.data });
      });
  };
};

export const deleteDiscountCouponsId = (id) => {
  return async (dispatch) => {
    return http
      .delete(`discount-coupons/${id}`)
      .then((response) => {
        dispatch({
          type: "DELETE_DISCOUNT_COUPONS_ID",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_DISCOUNT_COUPONS_ID",
          payload: error.response.data,
        });
        return Promise.reject(error.response?.data);
      });
  };
};

export const deletePOSButtonID = (posButtonId) => {
  return (dispatch) => {
    http
      .delete("pos-quick-button/" + posButtonId)
      .then((response) => {
        dispatch({ type: "DELETE_POS_BUTTON_ID", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_POS_BUTTON_ID",
          payload: error.response.data,
        });
      });
  };
};

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export const updateProduct = (formData) => {
  return async (dispatch) => {
    return await http
      .put("products/" + formData.productId, formData)
      .then((response) => {
        dispatch({ type: "PRODUCT_UPDATE", payload: response.data });
        return response;
      })
      .catch((error) => {
        dispatch({ type: "PRODUCT_UPDATE", payload: error.response.data });
        uiNotification.error(
          languageData[error.response.data.message] ||
            tInventory(
              `updatingProduct.apiError.${error.response.data.message}`,
            ),
        );
        return Promise.reject(error.response.data);
      });
  };
};

export const getReconciliationData = (formData) => {
  return (dispatch) => {
    http
      .get("inventory-reconciliation", formData)
      .then((response) => {
        dispatch({ type: "GET_RECONCILIATION_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_RECONCILIATION_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const fetchCategories = (formData) => {
  return (dispatch) => {
    http
      .get("all-categories", formData)
      .then((response) => {
        dispatch({ type: "FETCH_CATEGORIES", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "FETCH_CATEGORIES", payload: error.response.data });
      });
  };
};

export const saveReconciledData = (formData) => {
  return (dispatch) => {
    http
      .put("inventory-reconciliation-save", formData)
      .then((response) => {
        dispatch({ type: "SAVE_RECONCILED_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_RECONCILED_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const getProductDetails = (formData) => {
  return (dispatch) => {
    http
      .get("inventory-reconciliation-detail", formData)
      .then((response) => {
        dispatch({ type: "GET_PRODUCT_DETAILS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_PRODUCT_DETAILS", payload: error.response.data });
      });
  };
};

export const getReconciledReports = (formData) => {
  return (dispatch) => {
    http
      .get("inventory-reconciled-products", formData)
      .then((response) => {
        dispatch({ type: "GET_RECONCILED_REPORT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_RECONCILED_REPORT",
          payload: error.response.data,
        });
      });
  };
};

export const addProductStock = (formData) => {
  return (dispatch) => {
    http
      .post("product/add-inventory/" + formData.productId, formData)
      .then((response) => {
        dispatch({ type: "ADD_PRODUCT_INVENTORY", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "ADD_PRODUCT_INVENTORY",
          payload: error.response.data,
        });
      });
  };
};

export const adjustProductStock = (formData) => {
  return (dispatch) => {
    http
      .post("product/add-remove-inventory/" + formData.productId, formData)
      .then((response) => {
        dispatch({ type: "ADJUST_PRODUCT_INVENTORY", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "ADJUST_PRODUCT_INVENTORY",
          payload: error.response.data,
        });
      });
  };
};

export function downloadReconciledReport(formData) {
  return (dispatch) => {
    http
      .get("inventory-reconciled-products", formData)
      .then((response) => {
        dispatch({
          type: "DOWNLOAD_RECONCILED_REPORT",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "DOWNLOAD_RECONCILED_REPORT",
          payload: error.response.data,
        });
      });
  };
}

export function checkIfReconciledForDay(formData) {
  return (dispatch) => {
    http
      .post("is-inventory-reconciled", formData)
      .then((response) => {
        dispatch({
          type: "CHECK_IF_RECONCILED_ALREADY",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CHECK_IF_RECONCILED_ALREADY",
          payload: error.response.data,
        });
      });
  };
}

export const getSupplierList = (formData) => {
  return (dispatch) => {
    http
      .get("suppliers", formData)
      .then((response) => {
        dispatch({ type: "GET_SUPPLIER_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_SUPPLIER_LIST", payload: error.response.data });
      });
  };
};

export function exportSupplierData(download_type) {
  return (dispatch) => {
    http
      .get("supplier-export?download_type=" + download_type)
      .then((response) => {
        dispatch({ type: "IN_EXPORT_FILE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "IN_EXPORT_FILE", payload: error.response.data });
      });
  };
}

export function fetchSupplier(supplierId) {
  return (dispatch) => {
    http
      .get("get-supplier-details/" + supplierId)
      .then((response) => {
        dispatch({ type: "SUPPLIER_DETAILS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SUPPLIER_DETAILS", payload: error.response.data });
      });
  };
}

export function makeProductInactivate(pId) {
  return (dispatch) => {
    http
      .put("product/inactivate/" + pId)
      .then((response) => {
        dispatch({ type: "MAKE_PRODUCT_INACTIVATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "MAKE_PRODUCT_INACTIVATE",
          payload: error.response.data,
        });
      });
  };
}

export function createEditSupplier(formData) {
  return (dispatch) => {
    http
      .post("add-edit-supplier", formData)
      .then((response) => {
        dispatch({ type: "ADD_EDIT_SUPPLIER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "ADD_EDIT_SUPPLIER", payload: error.response.data });
      });
  };
}

export function deleteSupplier(supplierId) {
  return (dispatch) => {
    http
      .get("delete-supplier/" + supplierId)
      .then((response) => {
        dispatch({ type: "DELETE_SUPPLIER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_SUPPLIER", payload: error.response.data });
      });
  };
}

export function supplierBulkUpload(formData) {
  return (dispatch) => {
    http
      .post("supplier-bulk-upload", formData)
      .then((response) => {
        dispatch({ type: "SUPPLIER_BULK_UPLOAD", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SUPPLIER_BULK_UPLOAD",
          payload: error.response.data,
        });
      });
  };
}

export function getAllPurchaseOrders(formData) {
  return (dispatch) => {
    http
      .get("purchase-orders", formData)
      .then((response) => {
        dispatch({ type: "PURCHASE_ORDERS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "PURCHASE_ORDERS_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function fetchOrderDetails(formData) {
  let finalUrl =
    formData && formData.id
      ? "get-purchase-order-details/" + formData.id
      : "get-purchase-order-details";

  return (dispatch) => {
    http
      .get(finalUrl)
      .then((response) => {
        dispatch({ type: "GET_ORDER_DETAILS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_ORDER_DETAILS", payload: error.response.data });
      });
  };
}

export function saveOrderInfo(formData) {
  return (dispatch) => {
    http
      .post("add-edit-purchase-order", formData)
      .then((response) => {
        dispatch({ type: "SAVE_PURCHASE_ORDER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_PURCHASE_ORDER", payload: error.response.data });
      });
  };
}

export function receiveProduct(formData) {
  return (dispatch) => {
    http
      .post("add-received-items", formData)
      .then((response) => {
        dispatch({ type: "RECEIVE_PRODUCT", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "RECEIVE_PRODUCT", payload: error.response.data });
      });
  };
}

export function saveReorderSettings(formData) {
  return (dispatch) => {
    http
      .post("add-product-reorder-setting", formData)
      .then((response) => {
        dispatch({ type: "SAVE_REORDER_SETTING", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_REORDER_SETTING",
          payload: error.response.data,
        });
      });
  };
}

export function deletePurchaseOrder(id) {
  return (dispatch) => {
    http
      .get("delete-purchase-order/" + id)
      .then((response) => {
        dispatch({ type: "DELETE_PURCHASE_ORDER", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_PURCHASE_ORDER",
          payload: error.response.data,
        });
      });
  };
}

export function downloadOrder(id) {
  return (dispatch) => {
    http
      .post("download-purchase-order/" + id)
      .then((response) => {
        dispatch({ type: "DOWNLOAD_PURCHASE_ORDER", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DOWNLOAD_PURCHASE_ORDER",
          payload: error.response.data,
        });
      });
  };
}

export function editOrderItem(formData) {
  return async (dispatch) => {
    return http
      .post("update-order-item", formData)
      .then((response) => {
        dispatch({ type: "EDIT_ORDER_ITEM", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "EDIT_ORDER_ITEM", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function getStockTransfers(formData) {
  return (dispatch) => {
    http
      .get("get-transfer", formData)
      .then((response) => {
        dispatch({ type: "GET_STOCK_TRANSFERS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_STOCK_TRANSFERS", payload: error.response.data });
      });
  };
}

export function createStockTransfer(formData) {
  return (dispatch) => {
    http
      .post("create-transfer", formData)
      .then((response) => {
        dispatch({ type: "CREATE_STOCK_TRANSFER", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_STOCK_TRANSFER",
          payload: error.response.data,
        });
      });
  };
}

export function viewStockTransfer(stockTransferId) {
  return (dispatch) => {
    http
      .get("transfer-detail?id=" + stockTransferId)
      .then((response) => {
        dispatch({ type: "VIEW_STOCK_TRANSFER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "VIEW_STOCK_TRANSFER", payload: error.response.data });
      });
  };
}

export function acceptStockTransfer(formData) {
  return (dispatch) => {
    http
      .post("accept-order", formData)
      .then((response) => {
        dispatch({ type: "ACCEPT_STOCK_TRANSFER", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "ACCEPT_STOCK_TRANSFER",
          payload: error.response.data,
        });
      });
  };
}

export function getProductStockDetail(productId, clinicId) {
  let apiEndPoint = "product-detail?id=" + productId;
  if (clinicId) {
    apiEndPoint += "&clinic_id=" + clinicId;
  }
  return (dispatch) => {
    http
      .get(apiEndPoint)
      .then((response) => {
        dispatch({ type: "GET_PRODUCT_STOCK_DETAIL", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_PRODUCT_STOCK_DETAIL",
          payload: error.response.data,
        });
      });
  };
}

export const searchProductStock = (formData) => {
  return (dispatch) => {
    http
      .get("search-stock-product?" + serialize(formData.params))
      .then((response) => {
        dispatch({ type: "AUTO_PRODUCT_SEARCH_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "AUTO_PRODUCT_SEARCH_LIST",
          payload: error.response.data,
        });
      });
  };
};

export function getSupplierClinics(supplierId) {
  return (dispatch) => {
    http
      .get("get-supplier-clinics?supplier_id=" + supplierId)
      .then((response) => {
        dispatch({ type: "GET_SUPPLIER_CLINICS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_SUPPLIER_CLINICS",
          payload: error.response.data,
        });
      });
  };
}

export function searchProduct(formData) {
  return async (dispatch) => {
    return http
      .get("discount-packages/search-product?" + serialize(formData.params))
      .then((response) => {
        dispatch({ type: "SEARCH_CHARTING_PRODUCT", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "action", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function findClinics(id) {
  return (dispatch) => {
    http
      .get("get-product-price-detail?id=" + id)
      .then((response) => {
        dispatch({ type: "GET_CHARTING_CLINICS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_CHARTING_CLINICS",
          payload: error.response.data,
        });
      });
  };
}

export function addNewPackage(formData) {
  return (dispatch) => {
    http
      .post("create-charting-packages", formData)
      .then((response) => {
        dispatch({ type: "CREATE_CHARTING_PACKAGES", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_CHARTING_PACKAGES",
          payload: error.response.data,
        });
        uiNotification.error(
          languageData[error.response.data.message] ||
            tInventory(
              `saveUpdateInventory.apiError.${error.response.data.message}`,
            ),
        );
      });
  };
}

export function getPackageData(id) {
  return (dispatch) => {
    http
      .get("charting-package-detail?id=" + id)
      .then((response) => {
        dispatch({ type: "GET_PACKAGE_DETAILS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_PACKAGE_DETAILS", payload: error.response.data });
      });
  };
}

export function updatePackage(formData) {
  return (dispatch) => {
    http
      .post("create-charting-packages", formData)
      .then((response) => {
        dispatch({ type: "UPDATE_CHARTING_PACKAGES", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_CHARTING_PACKAGES",
          payload: error.response.data,
        });
        uiNotification.error(
          languageData[error.response.data.message] ||
            tInventory(
              `saveUpdateInventory.apiError.${error.response.data.message}`,
            ),
        );
      });
  };
}

export function deletePackage(id) {
  return (dispatch) => {
    http
      .put("charting-package-active-inactive?id=" + id)
      .then((response) => {
        dispatch({ type: "PACKAGE_ACTIVE_INACTIVE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "PACKAGE_ACTIVE_INACTIVE",
          payload: error.response.data,
        });
      });
  };
}

export function deleteProductFromChartingPackage(formData) {
  return (dispatch) => {
    http
      .post("delete-package-product", formData)
      .then((response) => {
        dispatch({ type: "DELETE_PACKAGE_PRODUCT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_PACKAGE_PRODUCT",
          payload: error.response.data,
        });
      });
  };
}

export function emailOrder(id, formData) {
  return (dispatch) => {
    http
      .post("download-purchase-order/" + id, formData)
      .then((response) => {
        dispatch({ type: "EMAIL_PURCHASE_ORDER", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "EMAIL_PURCHASE_ORDER",
          payload: error.response.data,
        });
      });
  };
}

export function updateStockAlert(formData) {
  return (dispatch) => {
    http
      .post("update-clinic-stock-alert", formData)
      .then((response) => {
        dispatch({ type: "UPDATE_STOCK_ALERT", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_STOCK_ALERT", payload: error.response.data });
      });
  };
}

export function updateCTC(formData) {
  return (dispatch) => {
    http
      .post(
        "product/edit-cost-to-company/" +
          formData.inventory_id +
          "/" +
          formData.product_id,
        formData,
      )
      .then((response) => {
        dispatch({ type: "UPDATE_CTC", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_CTC", payload: error.response.data });
      });
  };
}

export function searchPOProduct(formData) {
  return async (dispatch) => {
    return http
      .get("po/search-product?" + serialize(formData.params))
      .then((response) => {
        dispatch({ type: "SEARCH_PO_PRODUCT", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "action", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function updateStockAlertForOthers(formData) {
  return (dispatch) => {
    http
      .post(
        "product/edit-stock-alert/" +
          formData.inventory_id +
          "/" +
          formData.product_id,
        formData,
      )
      .then((response) => {
        dispatch({ type: "UPDATE_STOCK_ALERT", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_STOCK_ALERT", payload: error.response.data });
      });
  };
}

export function searchProductForRecipe(formData) {
  return (dispatch) => {
    http
      .get("charting-product-category-search", formData)
      .then((response) => {
        dispatch({ type: "CHARTING_PRODUCT_SEARCH", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CHARTING_PRODUCT_SEARCH",
          payload: error.response.data,
        });
      });
  };
}

export function searchCategoryForRecipe(formData) {
  return (dispatch) => {
    http
      .get("charting-product-category-search", formData)
      .then((response) => {
        dispatch({ type: "CHARTING_CATEGORY_SEARCH", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CHARTING_CATEGORY_SEARCH",
          payload: error.response.data,
        });
      });
  };
}

export function searchProductCategory(formData) {
  return (dispatch) => {
    http
      .post("charting-product-category-search", formData)
      .then((response) => {
        dispatch({
          type: "CHARTING_CATEGORY_PRODUCT_LIST",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CHARTING_CATEGORY_PRODUCT_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function submitCollectiveTotal(formData) {
  return (dispatch) => {
    http
      .post("create-charting-packages", formData)
      .then((response) => {
        dispatch({ type: "CREATE_COLLECTIVE_PACKAGE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_COLLECTIVE_PACKAGE",
          payload: error.response.data,
        });
        uiNotification.error(
          languageData[error.response.data.message] ||
            tInventory(
              `saveUpdateInventory.apiError.${error.response.data.message}`,
            ),
        );
      });
  };
}

export function deleteProductForCollective(formData) {
  return (dispatch) => {
    http
      .post("delete-bucket-product ", formData)
      .then((response) => {
        dispatch({ type: "DELETE_BUCKET_PRODUCT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_BUCKET_PRODUCT",
          payload: error.response.data,
        });
      });
  };
}

export function deleteBucket(formData) {
  return (dispatch) => {
    http
      .post("delete-bucket", formData)
      .then((response) => {
        dispatch({ type: "DELETE_BUCKET", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_BUCKET", payload: error.response.data });
      });
  };
}

export function getProductsForMedicalAndRetail(formData) {
  return (dispatch) => {
    http
      .post("get-product-and-category-by-label", formData)
      .then((response) => {
        dispatch({
          type: "GET_MEDICAL_AND_RETAIL_PRODUCTS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_MEDICAL_AND_RETAIL_PRODUCTS",
          payload: error.response.data,
        });
      });
  };
}

export function searchProductCategoryForSingleCategory(formData) {
  return (dispatch) => {
    http
      .post("charting-product-category-search", formData)
      .then((response) => {
        dispatch({
          type: "CHARTING_SINGLE_CATEGORY_PRODUCT_LIST",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CHARTING_SINGLE_CATEGORY_PRODUCT_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function showDetailedLogs(formData) {
  return (dispatch) => {
    http
      .get("get-all-product-inventory-logs", formData)
      .then((response) => {
        dispatch({ type: "GET_DETAILED_PRODUCT_LOGS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_DETAILED_PRODUCT_LOGS",
          payload: error.response.data,
        });
      });
  };
}
