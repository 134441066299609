import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";
import { http } from "../../../../../services/HttpService";

export const useStoreEnrollment = (options = {}) => {
  return useMutation(
    (dto) =>
      http.put(HTTP_ENDPOINTS.repeatMdIntegration.storeEnrollment(), dto),
    options,
  );
};
