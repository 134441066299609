/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import InsightsSideBar from "../InsightsSideBar.js";
import Loader from "../../Common/Loader.js";
import AppointmentTitle from "./AppointmentTitle.js";
import AppointmentsReportTitle from "./AppointmentsReportTitle.js";
import {
  resetReducerAction,
  getReportData,
  getOptionAndList,
  getReportVariables,
  saveReport,
  downloadReportData,
  getReportDetail,
} from "../../../Actions/BusinessInsights/businessInsightActions.js";
import { autoScrolling } from "../../../Utils/services.js";
import AppointmentsReportOptions from "./AppointmentsReportOptions.js";
import SaveReport from "../SaveReport.js";
import AppointmentsInsightDetailReport from "./AppointmentsInsightDetailReport.js";
import BookedAppointmentsPerClinic from "./ReportFiles/BookedAppointmentsPerClinic.js";
import AppointmentsProviderDetailReport from "./AppointmentsProviderDetailReport.js";
import { REPORT_TYPES } from "../../../consts/api.js";
import { uiNotification } from "../../../services/UINotificationService.js";

class AppointmentsInsightReport extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      showLoader: false,
      globalLang: languageData.global,
      businessInsightLang: languageData.business_insights,
      tableColumns: [],
      showCreateOptions: false,
      reportOptions: [],
      hasData: false,
      showBreadCrumb:
        (this.props.match.params &&
          this.props.match.params.reportType === "edit") ||
        (this.props.match.params &&
          this.props.match.params.reportType === "view")
          ? false
          : true,
      reportVariableData: [],
      showFilters: false,

      page: 1,
      pagesize: 15,
      startFresh: true,
      reportListData: [],
      next_page_url: "",
      reportMode:
        this.props.match.params &&
        this.props.match.params.reportID > 0 &&
        this.props.match.params.reportType === "edit"
          ? "edit"
          : this.props.match.params.reportType,
      showTableSection: false,
      showNameSection: false,
      reportNameClass: "simpleInput",
      reportName: "",
      dataLoadedOnce: false,
      propReportType:
        this.props.match.params && this.props.match.params.reportType
          ? this.props.match.params.reportType
          : "create",
      editReportID:
        this.props.match.params && this.props.match.params.reportID > 0
          ? this.props.match.params.reportID
          : 0,
      insightID:
        this.props.match.params && this.props.match.params.insightID > 0
          ? this.props.match.params.insightID
          : 0,

      downloadData: {},
      showGeneralReport: true,
      detailReportListData: [],
      childPage: 1,
      selected_report_type_sysname: "",
      categoryReportListData: [],
      serviceColumns: [],
    };

    window.onscroll = () => {
      if (
        ![
          REPORT_TYPES.noShowReport,
          REPORT_TYPES.cancellationReport,
          REPORT_TYPES.bookedServicesReport,
        ].includes(this.state.selected_report_type_sysname)
      ) {
        const scrollTop = parseInt(
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop,
          ),
        );
        if (
          document.documentElement.offsetHeight -
            (window.innerHeight + scrollTop) <=
            5 &&
          this.state.next_page_url != null
        ) {
          this.loadMore();
        }
      }
    };
  }

  componentDidMount() {
    autoScrolling(true);

    this.setState({
      showLoader: true,
      showNameSection: false,
      reportNameClass: "simpleInput",
      dataLoadedOnce: false,
      reportName: "",
    });

    if (
      this.state.reportMode === "view" &&
      this.state.propReportType === "view" &&
      this.state.editReportID &&
      this.state.insightID
    ) {
      const reportOptionFilter = {};
      reportOptionFilter.page = this.state.page;
      reportOptionFilter.pagesize = this.state.pagesize;
      reportOptionFilter.business_insight_reports_id = this.state.editReportID;

      this.setState({ selected_report_type_id: this.state.insightID });

      if (reportOptionFilter) {
        this.props.getReportData(this.state.insightID, reportOptionFilter);
      }
    } else {
      let formData = {};

      if (
        this.state.editReportID &&
        this.state.editReportID > 0 &&
        this.state.reportMode === "edit"
      ) {
        formData.business_insight_reports_id = this.state.editReportID;
      }
      this.props.getOptionAndList("appointments", formData);
    }
  }

  loadMore = () => {
    if (!autoScrolling()) {
      if (
        this.state.reportMode === "view" &&
        this.state.propReportType === "view" &&
        this.state.editReportID &&
        this.state.insightID
      ) {
        const reportOptionFilter = {};
        reportOptionFilter.page = this.state.page;
        reportOptionFilter.pagesize = this.state.pagesize;
        reportOptionFilter.business_insight_reports_id =
          this.state.editReportID;

        if (reportOptionFilter) {
          this.setState({ showLoadingText: true });
          autoScrolling(true);
          this.props.getReportData(this.state.insightID, reportOptionFilter);
        }
      } else {
        const reportOptionFilter = this.state.reportFilter;

        reportOptionFilter.page = this.state.page;
        reportOptionFilter.pagesize = this.state.pagesize;

        if (reportOptionFilter) {
          this.setState({ showLoadingText: true });
          autoScrolling(true);
          this.props.getReportData(
            reportOptionFilter.report_type_id,
            reportOptionFilter,
          );
        }
      }
    }
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ [event.target.name]: value, dataChanged: true });
  };

  showCreateOptions = () => {
    this.setState({ showCreateOptions: true });
  };

  hideCreateOptions = (childSate) => {
    this.setState(childSate);

    if (!this.state.dataLoadedOnce) {
      return (
        <div>{this.props.history.push(`/business-insight/appointments`)}</div>
      );
    } else {
      this.setState({ showCreateOptions: false, showTableSection: true });
    }
  };

  getData = (childSate) => {
    this.setState(childSate);
    autoScrolling(true);

    if (
      childSate.report_type_sysname !== "no-show-report" ||
      childSate.report_type_sysname !== "cancellation-report"
    ) {
      childSate.reportFilter.page = 1;
      childSate.reportFilter.pagesize = this.state.pagesize;
    }

    this.setState(
      {
        reportListData: [],
        showLoader: true,
        page: 1,
        startFresh: true,
        next_page_url: "",
        insightReportData: [],
        grammar: null,
        selected_report_type_id: childSate.report_type_id,
        selected_report_type_sysname: childSate.report_type_sysname,
      },
      () => {
        this.props.getReportData(
          childSate.report_type_id,
          childSate.reportFilter,
        );
      },
    );
  };

  getReportVariables = (childSate, isVariableData) => {
    this.setState(childSate);
    if (isVariableData) {
      this.props.getReportVariables(childSate.report_type_id);
    }
  };

  static getDerivedStateFromProps(props, state) {
    let returnState = {};

    if (
      props.reportData !== undefined &&
      props.reportData.status === 200 &&
      props.reportData.data !== state.reportData &&
      props.reportData.data.next_page_url !== state.next_page_url
    ) {
      returnState.tableColumns = props.reportData.data.columns;

      if (state.next_page_url === null) {
        autoScrolling(false);
        return (returnState.next_page_url = null);
      }

      if (
        state.reportListData &&
        state.reportListData.length === 0 &&
        state.startFresh === true
      ) {
        returnState.insightReportData = props.reportData.data;

        if (props.reportData.data.next_page_url !== null) {
          returnState.page = state.page + 1;
        } else {
          returnState.next_page_url = props.reportData.data.next_page_url;
        }
        returnState.startFresh = false;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
        returnState.reportListData = props.reportData.data.data
          ? props.reportData.data.data
          : [];
        returnState.selected_report_type_sysname =
          props.reportData.data.sys_name;
        returnState.showTableSection = true;
        returnState.dataLoadedOnce = true;

        if (state.propReportType && state.propReportType === "view") {
          returnState.selected_report_type_id = state.insightID;
        }

        returnState.selected_report_type_sysname = props.reportData.data
          .sys_name
          ? props.reportData.data.sys_name
          : "";
      } else if (
        state.insightReportData !== props.reportData.data &&
        state.insightReportData.length !== 0
      ) {
        returnState.reportListData = [
          ...state.reportListData,
          ...props.reportData.data.data,
        ];
        returnState.insightReportData = props.reportData.data;
        returnState.selected_report_type_sysname =
          props.reportData.data.sys_name;
        returnState.next_page_url = props.reportData.data.next_page_url;
        returnState.showLoader = false;
        returnState.page = state.page + 1;
        returnState.showLoadingText = false;
      }

      autoScrolling(false);
      props.resetReducerAction();
    } else if (
      props.reportData !== undefined &&
      props.reportData.status !== 200 &&
      props.reportData.data !== state.reportData
    ) {
      returnState.reportData = props.reportData.data;
      returnState.showLoader = false;
      returnState.next_page_url = null;
      props.resetReducerAction();
    }

    if (
      props.reportOptionAndList &&
      props.reportOptionAndList !== state.reportOptionAndList
    ) {
      returnState.reportOptionAndList = props.reportOptionAndList;
      returnState.reportOptions =
        props.reportOptionAndList.patientReportOptions;
      returnState.savedReports = props.reportOptionAndList.savedReports;
      returnState.hasData = false;
      returnState.showLoader = false;
      returnState.showCreateOptions =
        state.propReportType && state.propReportType === "view" ? false : true;
      returnState.allData = props.reportOptionAndList.allData;
    }

    if (props.reportOptions && props.reportOptions !== state.reportOptions) {
      returnState.reportOptions = props.reportOptions;
      //returnState.showLoader            = false;
    }

    if (
      props.reportVariableData &&
      props.reportVariableData !== state.reportVariableData
    ) {
      returnState.reportVariableData = props.reportVariableData;
      returnState.showLoader = false;
      returnState.showFilters = true;
    }

    if (
      props.reportSavedData !== undefined &&
      props.reportSavedData.status === 200 &&
      props.reportSavedData.data !== state.reportSavedData
    ) {
      returnState.reportSavedData = props.reportSavedData.data;
      returnState.savedStatus = props.reportSavedData.message;
      returnState.showNameSection = false;
    } else if (
      props.reportSavedData !== undefined &&
      props.reportSavedData.status !== 200 &&
      props.reportSavedData.data !== state.reportSavedData
    ) {
      returnState.reportSavedData = props.reportSavedData.data;
      returnState.savedStatus = "";
      returnState.showLoader = false;
    }

    if (props.showLoader !== undefined && props.showLoader === false) {
      returnState.showLoader = false;
      props.resetReducerAction();
    } else if (
      props.downloadData !== undefined &&
      props.downloadData !== state.downloadData
    ) {
      returnState.showLoader = false;
      returnState.downloadData = props.downloadData;
      props.resetReducerAction();
      window.open(
        process.env.REACT_APP_API_URL +
          "download-data/" +
          props.downloadData.file,
        "_blank",
      );
    } else if (
      props.reportDetail !== undefined &&
      props.reportDetail.status === 200 &&
      props.reportDetail.data !== state.detailReportListData &&
      props.reportDetail.data.next_page_url !== state.next_page_url
    ) {
      returnState.detailTableColumns = props.reportDetail.data.columns
        ? props.reportDetail.data.columns
        : state.detailTableColumns;

      if (state.next_page_url === null) {
        autoScrolling(false);
        return (returnState.next_page_url = null);
      }

      if (
        state.detailReportListData &&
        state.detailReportListData.length === 0 &&
        state.startFresh === true
      ) {
        returnState.reportDetail = props.reportDetail.data;

        if (props.reportDetail.data.next_page_url !== null) {
          returnState.childPage = state.childPage + 1;
        } else {
          returnState.next_page_url = props.reportDetail.data.next_page_url;
        }

        returnState.startFresh = false;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
        returnState.detailReportListData = props.reportDetail.data.data
          ? props.reportDetail.data.data
          : [];
        returnState.grammar = props.reportDetail.data.grammar
          ? props.reportDetail.data.grammar
          : "";
        returnState.childTotal = props.reportDetail.data.total
          ? props.reportDetail.data.total
          : "";
        returnState.showGeneralReport = false;

        returnState.serviceColumns =
          props.reportDetail.data && props.reportDetail.data.service_columns
            ? props.reportDetail.data.service_columns
            : [];

        returnState.categoryReportListData =
          props.reportDetail.data &&
          props.reportDetail.data.service_category_data
            ? props.reportDetail.data.service_category_data
            : [];
      } else if (
        state.reportDetail !== props.reportDetail.data &&
        state.reportDetail.length !== 0
      ) {
        returnState.detailReportListData = [
          ...state.detailReportListData,
          ...props.reportDetail.data.data,
        ];
        returnState.reportDetail = props.reportDetail.data;
        returnState.next_page_url = props.reportDetail.data.next_page_url;
        returnState.showLoader = false;
        returnState.childPage = state.childPage + 1;
        returnState.showLoadingText = false;
      }

      autoScrolling(false);
      props.resetReducerAction();
    } else if (
      props.reportDetail !== undefined &&
      props.reportDetail.status !== 200 &&
      props.reportDetail.data !== state.reportDetail
    ) {
      returnState.reportDetail = props.reportDetail.data;
      returnState.showLoader = false;
      returnState.next_page_url = null;
      props.resetReducerAction();
    }
    return returnState;
  }

  componentDidUpdate = (_, prevState) => {
    if (
      this.state.reportSavedData !== null &&
      this.state.reportSavedData !== "" &&
      this.state.reportSavedData !== prevState.reportSavedData &&
      this.state.savedStatus !== null &&
      this.state.savedStatus !== ""
    ) {
      uiNotification.clear();
      uiNotification.success(this.state.globalLang[this.state.savedStatus]);

      return (
        <div>{this.props.history.push(`/business-insight/appointments`)}</div>
      );
    }
  };

  getReportName = () => {
    this.setState({ showNameSection: true });
  };

  hideNameSection = (childState) => {
    this.setState(childState);
  };

  saveThisReport = () => {
    let reportName = this.state.reportName;

    if (reportName && reportName.trim() !== "") {
      this.setState({
        showLoader: true,
        reportNameClass: "simpleInput",
        reportName: "",
        showNameSection: false,
      });

      let reportFilter = this.state.reportFilter;
      reportFilter.business_insight_id = this.state.reportFilter.report_type_id;
      reportFilter.name = reportName;

      if (
        this.state.editReportID &&
        this.state.editReportID > 0 &&
        this.state.reportMode === "edit"
      ) {
        reportFilter.business_insight_report_id = this.state.editReportID;
      }

      if (
        this.state.insightReportData &&
        this.state.insightReportData.grammar
      ) {
        reportFilter.grammar = this.state.insightReportData.grammar;
      }

      this.props.saveReport("appointments", reportFilter);
    } else {
      this.setState({ reportNameClass: "simpleInput field-error" });
      return;
    }
  };

  handleChildState = (childState) => {
    this.setState(childState);
  };

  gotToNext = () => {
    return (
      <div>
        {this.props.history.push(`/business-insight/appointments/create`)}
      </div>
    );
  };

  downloadData = () => {
    let reportOptionFilter = {};

    if (this.state.showGeneralReport) {
      if (
        this.state.reportMode === "view" &&
        this.state.propReportType === "view" &&
        this.state.editReportID &&
        this.state.insightID
      ) {
        reportOptionFilter.business_insight_reports_id =
          this.state.editReportID;
        reportOptionFilter.is_download = 1;

        if (reportOptionFilter) {
          this.setState({ showLoader: true });
          this.props.downloadReportData(
            this.state.insightID,
            reportOptionFilter,
          );
        }
      } else {
        const reportOptionFilter = this.state.reportFilter;
        reportOptionFilter.is_download = 1;

        if (reportOptionFilter) {
          this.setState({ showLoader: true });
          this.props.downloadReportData(
            reportOptionFilter.report_type_id,
            reportOptionFilter,
          );
        }
      }
    } else {
      if (
        this.state.propReportType === "view" &&
        this.state.editReportID &&
        this.state.insightID
      ) {
        reportOptionFilter.business_insight_reports_id =
          this.state.editReportID;
      } else {
        reportOptionFilter = JSON.parse(
          JSON.stringify(this.state.reportFilter),
        );
      }
      reportOptionFilter.is_download = 1;
      reportOptionFilter.show_report_by_id = this.state.show_report_by_id;
      reportOptionFilter.show_report_by = this.state.show_report_by;
      reportOptionFilter.clinic = this.state.reportDetail?.data?.clinic_id || 0;
      this.setState({ showLoader: true });
      this.props.downloadReportData(
        this.state.business_insight_child_id,
        reportOptionFilter,
      );
    }
  };

  goToDetail = (reportTypeID, typeID, typeName, clinicId) => {
    if (reportTypeID && typeID) {
      let reportOptionFilter = {};

      if (
        this.state.propReportType === "view" &&
        this.state.editReportID &&
        this.state.insightID
      ) {
        reportOptionFilter.business_insight_reports_id =
          this.state.editReportID;
      } else {
        reportOptionFilter = JSON.parse(
          JSON.stringify(this.state.reportFilter),
        );
      }

      reportOptionFilter.show_report_by_id = typeID;
      reportOptionFilter.show_report_by = typeName;
      reportOptionFilter.clinic = clinicId;
      reportOptionFilter.page = 1;
      reportOptionFilter.pagesize = this.state.pagesize;

      if (reportOptionFilter.is_download) {
        delete reportOptionFilter.is_download;
      }

      this.setState({
        show_report_by: typeName,
        showLoader: true,
        business_insight_child_id: reportTypeID,
        show_report_by_id: typeID,
        startFresh: true,
        next_page_url: "",
        detailReportListData: [],
        categoryReportListData: [],
        clinic: clinicId,
      });

      if (
        this.state.selected_report_type_sysname === "no-show-report" ||
        this.state.selected_report_type_sysname === "cancellation-report" ||
        this.state.selected_report_type_sysname ===
          "booked-appointments-per-provider"
      ) {
        this.props.getReportDetail(reportTypeID, reportOptionFilter);
      }
    }
  };

  loadChildMore = () => {
    if (!autoScrolling()) {
      if (
        this.state.reportMode === "view" &&
        this.state.propReportType === "view" &&
        this.state.editReportID &&
        this.state.insightID
      ) {
        const reportOptionFilter = this.state.reportFilter;
        reportOptionFilter.page = this.state.childPage;
        reportOptionFilter.pagesize = this.state.pagesize;
        reportOptionFilter.show_report_by_id = this.state.show_report_by_id;
        reportOptionFilter.show_report_by = this.state.show_report_by;
        reportOptionFilter.business_insight_reports_id =
          this.state.editReportID;

        if (reportOptionFilter) {
          this.setState({ showLoadingText: true });
          autoScrolling(true);
          this.props.getReportDetail(
            this.state.business_insight_child_id,
            reportOptionFilter,
          );
        }
      } else {
        const reportOptionFilter = this.state.reportFilter;
        reportOptionFilter.page = this.state.childPage;
        reportOptionFilter.pagesize = this.state.pagesize;
        reportOptionFilter.show_report_by_id = this.state.show_report_by_id;
        reportOptionFilter.show_report_by = this.state.show_report_by;

        if (reportOptionFilter) {
          this.setState({ showLoadingText: true });
          autoScrolling(true);
          this.props.getReportDetail(
            this.state.business_insight_child_id,
            reportOptionFilter,
          );
        }
      }
    }
  };

  dismissCloseDetail = (childState) => {
    this.setState(childState);
  };

  render() {
    return (
      <div id="content">
        <SaveReport
          showNameSection={this.state.showNameSection}
          hideNameSection={this.hideNameSection}
          reportNameClass={this.state.reportNameClass}
          saveThisReport={this.saveThisReport}
          reportName={this.state.reportName}
          handleChildState={this.handleChildState}
          reportMode={this.state.reportMode}
          editReportID={this.state.editReportID}
          propReportType={this.state.propReportType}
        />

        <AppointmentsReportOptions
          showCreateOptions={this.state.showCreateOptions}
          getData={this.getData}
          hideCreateOptions={this.hideCreateOptions}
          getReportVariables={this.getReportVariables}
          reportOptions={this.state.reportOptions}
          reportVariableData={this.state.reportVariableData}
          showFilters={this.state.showFilters}
          allData={this.state.allData}
          reportMode={this.state.reportMode}
          editReportID={this.state.editReportID}
          propReportType={this.state.propReportType}
          businessInsightLang={this.state.businessInsightLang}
          globalLang={this.state.globalLang}
          history={this.props.history}
        />

        <div className="container-fluid content setting-wrapper">
          <InsightsSideBar />

          <div className="memberWalletOuter business-section">
            {this.state.showGeneralReport === true && (
              <>
                <AppointmentTitle
                  hasData={this.state.hasData}
                  showBreadCrumb={this.state.showBreadCrumb}
                  reportMode={this.state.reportMode}
                  gotToNext={() => this.gotToNext()}
                  history={this.props.history}
                  businessInsightLang={this.state.businessInsightLang}
                  globalLang={this.state.globalLang}
                />

                <div
                  className={
                    this.state.showTableSection
                      ? "setting-setion m-b-10"
                      : "setting-setion m-b-10 no-display"
                  }
                >
                  <AppointmentsReportTitle
                    parentState={this.state}
                    showCreateOptions={this.showCreateOptions}
                    saveReport={this.getReportName}
                    reportMode={this.state.reportMode}
                    downloadData={this.downloadData}
                  />

                  {["booked-appointments-per-clinic"].indexOf(
                    this.state.selected_report_type_sysname,
                  ) == -1 && (
                    <div className="table-responsive clients-table no-border">
                      <table className="table-updated  table-min-width no-td-border no-hover">
                        <thead className="table-updated-thead">
                          <tr>
                            {this.state.tableColumns &&
                              this.state.tableColumns.length > 0 &&
                              this.state.tableColumns.map((tobj, tidx) => {
                                return (
                                  <th key={tidx} className="table-updated-th">
                                    {this.state.businessInsightLang[tobj]}
                                  </th>
                                );
                              })}
                          </tr>
                        </thead>
                        <tbody className="ajax_body">
                          {this.state.reportListData &&
                            this.state.reportListData.length > 0 &&
                            this.state.reportListData.map((robj, ridx) => {
                              return (
                                <tr key={ridx} className="table-updated-tr">
                                  <React.Fragment>
                                    {this.state.tableColumns &&
                                      this.state.tableColumns.length > 0 &&
                                      this.state.tableColumns.map(
                                        (tobj, tidx) => {
                                          return this.state
                                            .showGeneralReport === true &&
                                            tobj === "bi_view_details" ? (
                                            <td
                                              key={ridx + `_` + tidx}
                                              className={"table-updated-td"}
                                            >
                                              {(this.state
                                                .selected_report_type_sysname ===
                                                "no-show-report" ||
                                                this.state
                                                  .selected_report_type_sysname ===
                                                  "cancellation-report" ||
                                                this.state
                                                  .selected_report_type_sysname ===
                                                  "booked-appointments-per-provider") && (
                                                <a
                                                  className="easy-link no-padding"
                                                  onClick={() =>
                                                    this.goToDetail(
                                                      robj.business_insight_child_id,
                                                      robj.show_report_by_id,
                                                      robj.show_report_by,
                                                      robj.clinic_id,
                                                    )
                                                  }
                                                >
                                                  {"View Details"}
                                                </a>
                                              )}
                                            </td>
                                          ) : (
                                            <td
                                              key={ridx + `_` + tidx}
                                              className={
                                                [
                                                  "bi_email",
                                                  "bi_phone",
                                                ].indexOf(tobj) > -1
                                                  ? "table-updated-td break-all"
                                                  : "table-updated-td"
                                              }
                                            >
                                              {robj[tobj]}
                                            </td>
                                          );
                                        },
                                      )}
                                  </React.Fragment>
                                </tr>
                              );
                            })}
                          {this.state.reportListData &&
                            this.state.reportListData.length <= 0 &&
                            this.state.showLoader === false &&
                            this.state.startFresh === false && (
                              <tr className="table-updated-tr">
                                <td
                                  className="table-updated-td no-record no-float"
                                  colSpan={10}
                                >
                                  {
                                    this.state.businessInsightLang
                                      .bi_no_record_found
                                  }
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                {this.state.selected_report_type_sysname ==
                  "booked-appointments-per-clinic" && (
                  <BookedAppointmentsPerClinic
                    reportData={
                      this.state.insightReportData &&
                      this.state.insightReportData.total_availability
                        ? this.state.insightReportData
                        : undefined
                    }
                  />
                )}
                <div
                  className={
                    this.state.showLoadingText
                      ? "loading-please-wait no-margin-top"
                      : "loading-please-wait no-margin-top no-display"
                  }
                >
                  {this.state.globalLang.loading_please_wait_text}
                </div>
              </>
            )}

            {this.state.showGeneralReport === false &&
              (this.state.selected_report_type_sysname === "no-show-report" ||
                this.state.selected_report_type_sysname ===
                  "cancellation-report") && (
                <AppointmentsInsightDetailReport
                  parentState={this.state}
                  detailReportListData={this.state.detailReportListData}
                  downloadData={this.downloadData}
                  grammar={this.state.grammar}
                  childTotal={this.state.childTotal}
                  dismissCloseDetail={this.dismissCloseDetail}
                  tableColumns={this.state.detailTableColumns}
                  loadChildMore={this.loadChildMore}
                  categoryReportListData={this.state.categoryReportListData}
                />
              )}

            {this.state.showGeneralReport === false &&
              this.state.selected_report_type_sysname ===
                "booked-appointments-per-provider" && (
                <AppointmentsProviderDetailReport
                  parentState={this.state}
                  downloadData={this.downloadData}
                  grammar={this.state.grammar}
                  childTotal={this.state.childTotal}
                  dismissCloseDetail={this.dismissCloseDetail}
                  tableColumns={this.state.serviceColumns}
                  detailReportListData={this.state.categoryReportListData}
                  tabData={this.state.reportDetail}
                />
              )}
          </div>
        </div>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  if (
    state.BusinessInsightReducer.appointmentsOptions &&
    state.BusinessInsightReducer.appointmentsOptions.length
  ) {
    returnState.reportOptions =
      state.BusinessInsightReducer.appointmentsOptions;
  }

  if (
    state.BusinessInsightReducer.appointmentsSavedReports &&
    state.BusinessInsightReducer.appointmentsSavedReports.length
  ) {
    returnState.savedReports =
      state.BusinessInsightReducer.appointmentsSavedReports;
  }

  if (state.BusinessInsightReducer.action === "GET_REPORT_DATA") {
    if (state.BusinessInsightReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.reportData = state.BusinessInsightReducer.data;
    } else {
      returnState.reportData = state.BusinessInsightReducer.data;
    }
  }

  if (state.BusinessInsightReducer.action === "GET_APPOINTMENTS_REPORT") {
    if (state.BusinessInsightReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.reportOptionAndList = {
        savedReports: state.BusinessInsightReducer.appointmentsSavedReports,
        reportOptions: state.BusinessInsightReducer.appointmentsOptions,
        allData: state.BusinessInsightReducer.data.data,
      };
    }
  }

  if (state.BusinessInsightReducer.action === "GET_REPORT_VARIABLES") {
    if (state.BusinessInsightReducer.data.status !== 200) {
      returnState.showLoader = false;
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    } else {
      returnState.reportVariableData = state.BusinessInsightReducer.data.data;
    }
  }

  if (
    state.BusinessInsightReducer.action === "UPDATE_APPOINTMENTS_SAVED_REPORTS"
  ) {
    if (state.BusinessInsightReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );

      state.BusinessInsightReducer.data.data = moment().unix();
      returnState.reportSavedData = state.BusinessInsightReducer.data;
    } else {
      state.BusinessInsightReducer.data.data = moment().unix();
      returnState.reportSavedData = state.BusinessInsightReducer.data;
    }
  }

  if (state.BusinessInsightReducer.action === "DOWNLOAD_REPORT_DATA") {
    if (state.BusinessInsightReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.downloadData = state.BusinessInsightReducer.data.data;
    }
  }
  if (state.BusinessInsightReducer.action === "GET_REPORT_DETAIL") {
    if (state.BusinessInsightReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.reportDetail = state.BusinessInsightReducer.data;
    } else {
      returnState.reportDetail = state.BusinessInsightReducer.data;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetReducerAction: resetReducerAction,
      getReportData: getReportData,
      getOptionAndList: getOptionAndList,
      getReportVariables: getReportVariables,
      saveReport: saveReport,
      downloadReportData: downloadReportData,
      getReportDetail: getReportDetail,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AppointmentsInsightReport));
