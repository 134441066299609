import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import {
  API_DATE_FORMAT,
  FILE_EXPORT_TYPES,
  HTTP_ENDPOINTS,
} from "../../consts/api";
import { http } from "../../services/HttpService";
import {
  packedListOr,
  passOr,
  removeNullishFromShape,
  unwrapOr,
} from "../../utilities/general";

export const SALES_REPORT_EXPORT_NAMES = {
  membershipOverviewReport: "new_membership_overview",
  membershipChurnReport: "new_membership_churn_report",
  membershipDeclineReport: "new_membership_decline_report",
  qualiphyReport: "qualiphy_report",
  retentionRate: "retention_rate",
  retentionRateDetails: "retention_rate_details",
  waitList: "waitlist",
  expiredLiabilityReport: "expired_short_term_liability",
  expiringLiabilityReport: "expiring_short_term_liability",
  reconciliationReport: "reconciliation_report",
  shortTermLiability: "short_term_liability",
  smsUsage: "sms_usage",
  efaxUsage: "efax_usage",
  shopifyOrders: "shopify_orders",
};

const requestSchema = yup.object({
  type: yup
    .string()
    .test({
      test: (value) => Object.values(FILE_EXPORT_TYPES).includes(value),
    })
    .required(),

  name: yup
    .string()
    .test({
      test: (value) => Object.values(SALES_REPORT_EXPORT_NAMES).includes(value),
    })
    .required(),

  fromDate: yup.date().nullable(),

  toDate: yup.date().nullable(),

  clinicIds: yup.array().of(yup.number()).nullable(),

  providerIds: yup.array().of(yup.number()).nullable(),

  tierIds: yup.array().of(yup.number()).nullable(),

  churnReason: yup.string().nullable(),

  paymentFrequency: yup.string().nullable(),

  status: yup.string().nullable(),

  sortBy: yup.string().nullable(),

  order: yup.string().nullable(),

  waitList: yup
    .object({
      sort_dir: yup.string().nullable(),
      sort_by: yup.string().nullable(),
      month: yup.number().nullable(),
      clinic_id: yup.number().nullable(),
      provider_id: yup.number().nullable(),
      service_id: yup.number().nullable(),
      timeslot_id: yup.number().nullable(),
      day: yup.number().nullable(),
      year: yup.number().nullable(),
      status: yup.string().nullable(),
      search: yup.string().nullable(),
    })
    .nullable(),

  rawPayload: yup.object().nullable(),
});

const responseSchema = yup.object({
  fileName: yup.string().required(),
});

const composeResponse = (res) => {
  const data = unwrapOr(() => res.data.data, {});
  return {
    fileName: data.file,
  };
};

export function useSalesReportExportMutation(options = {}) {
  return useMutation(async (payload) => {
    const req = requestSchema.validateSync(payload, {
      strict: true,
    });

    const fromDate = passOr(req.fromDate, null, () =>
      moment(req.fromDate).format(API_DATE_FORMAT),
    );

    const toDate = passOr(req.toDate, null, () =>
      moment(req.toDate).format(API_DATE_FORMAT),
    );

    const reqPayload = req.rawPayload || {
      fromDate,
      toDate,
      clinic_ids: packedListOr(req.clinicIds, null),
      provider_ids: packedListOr(req.providerIds, null),
      tier_ids: packedListOr(req.tierIds, null),
      churn_reason: req.churnReason,
      payment_frequency: req.paymentFrequency,
      status: req.status,
      sortBy: req.sortBy,
      order: req.order,
      ...(req.waitList || {}),
    };

    const res = await http.post(
      HTTP_ENDPOINTS.postSalesReportExport(),
      removeNullishFromShape({
        export_type: req.type,
        export_name: req.name,
        ...reqPayload,
      }),
    );

    return responseSchema.validateSync(composeResponse(res), {
      strict: true,
    });
  }, options);
}
