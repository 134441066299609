import React, { useEffect } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { useChargeForm } from "../../hooks/useChargeForm";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import { usePaymentFlow } from "../../hooks/usePaymentFlow";
import PaymentFormModal from "./components/PaymentFormModal";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import { tCommon, tSales } from "../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../assets/svg";
import ViewTipsModal from "../ViewTipsModal/ViewTipsModal";
import { dispatch } from "../../../../../store/store";
import { PAYMENT_VIEW_TYPES } from "../../../../../store/checkoutInvoice/consts";
import ChangeCalculator from "./components/ChangeCalculator";

const ViewChargeCash = ({ isTipsApplying, isTipsOnlyFlow }) => {
  const { invoice, amountToPay, beforeTipAmount } = useInvoice();
  const { chargeCash, paymentViewClose } = useInvoiceActions();
  const { isTipsAvailable } = usePaymentFlow();

  const onlyTips = beforeTipAmount === 0 && invoice.tipAmount > 0;

  const handleSubmit = (amount) => {
    if (onlyTips && !isTipsOnlyFlow) {
      dispatch(
        checkoutInvoice.actions.paymentViewChange({
          paymentView: PAYMENT_VIEW_TYPES.optionSelection,
        }),
      );
      dispatch(checkoutInvoice.actions.changeIsTipsOnlyFlow(true));
      return;
    }
    chargeCash.initiate({
      amount,
    });
  };

  const schema = yup.object({
    amount: composeAmountSchema(
      {
        amountToPay,
        currency: invoice?.currency,
      },
      true,
    ),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount }) => {
        handleSubmit(amount);
      },
      initialValues: {
        amount: amountToPay,
      },
    });

  useEffect(() => {
    if (amountToPay === 0 && !isTipsAvailable) {
      submit({ amount: 0 });
    }
  }, []);

  const getChargeLabel = () => {
    if (isTipsOnlyFlow && form.amount == 0) {
      return tCommon("label.continue");
    }
    if (!isTipsOnlyFlow && beforeTipAmount === 0) {
      return tCommon("label.continue");
    }
    return tCommon("label.charge");
  };

  const getTitle = () => {
    if (!isTipsOnlyFlow && beforeTipAmount === 0) {
      return tSales("checkoutInvoice.paymentOptions.tipAmount");
    }
    return tSales("checkoutInvoice.paymentOptions.cash");
  };

  useEffect(() => {
    changeFormValue("amount", amountToPay);
  }, [amountToPay]);

  return (
    <ModalCharge
      isOpen
      onClose={paymentViewClose}
      title={getTitle()}
      imageSrc={svg.cashPrimary}
      onCharge={submit}
      isCharging={chargeCash.isLoading}
      chargeLabel={getChargeLabel()}
      amount={getChargeLabel() === tCommon("label.charge") ? form.amount : ""}
      isChargeDisabled={!isValid || isTipsApplying}
      headerActionButton={<ChangeCalculator invoiceAmount={form.amount} />}
    >
      <ViewTipsModal
        amountToPay={form.amount}
        isTipsOnlyFlow={isTipsOnlyFlow}
      />
      {(beforeTipAmount > 0 || isTipsOnlyFlow) && (
        <PaymentFormModal
          form={form}
          errors={errors}
          hasError={hasError}
          changeFormValue={changeFormValue}
        />
      )}
    </ModalCharge>
  );
};

const mapStateToProps = (state) => ({
  isTipsApplying: checkoutInvoice.selectors.selectIsTipsApplying(state),
  isTipsOnlyFlow: checkoutInvoice.selectors.selectIsTipsOnlyFlow(state),
});

export default connect(mapStateToProps)(ViewChargeCash);
